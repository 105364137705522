import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';
import {ReactComponent as Caja} from "../../../assets/svg/graficas/cash-register-solid.svg";
import {ReactComponent as Calendario} from "../../../assets/svg/graficas/calendar-day-solid.svg";
import {ReactComponent as Usuarios} from "../../../assets/svg/graficas/users-solid.svg";
import {getLUserDataApi, getUsers} from '../../../api/user'; 
import {getEventosAll, getEventosAdmin} from '../../../api/eventos'; 
import {ReactComponent as Eye} from "../../../assets/svg/eye-solid.svg";
import Modal from '../../../Components/ModalLg';
import VerEvento from '../../../Components/VerEvento';
import Activo from '../../../Components/Activo';
import moment from 'moment';
import './Principal.scss';
import useAuth from '../../../hooks/useAuth';

 

const fecha = Date.now()

const formatMes = (dateString) => {
  const options = {month: 'long' };
  return new Date(dateString).toLocaleDateString('es-MX', options)
}

const formatDay = (dateString) => {
  const options = {day: 'numeric', month: 'long'};
  return new Date(dateString).toLocaleDateString('es-MX', options)
}

/* const ingreso = (formatMes(fecha));

console.log(ingreso) */
 
const Principal = () => {
    const [reload, setReload] = useState(false);
    const [users, setUsers] = useState(undefined);
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [user, setUser] = useState(undefined);
    const {auth} = useAuth();
//LLamar eventos    
const [eventos, setEventos] = useState(undefined);
useEffect(() => {
(async () =>{
  const response = await getEventosAll(auth?.token);
  setEventos(response.evento);
  setReload(false)
})()
}, [auth, reload]);



    useEffect(() => {
      (async () =>{
        const response = await getLUserDataApi(auth.token, auth.id);
        setUser(response.userData);
      })()
    }, [auth]);

    //LLama a todos los usuarios
    useEffect(() => {
        (async () =>{
          const response = await getUsers(auth.token);
          setUsers(response.users);
        })()
      }, [auth]);
    return (
      <>
        <Container fluid className='admin__container'>
       {/*  <Container className='admin_dasboard_nab'>
         <div className="fecha_hoy">
         {Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(fecha)}
         </div>
        <div className='Contaier_navBar_principal'>
         <div className="datauser">
             <p><span>Hola, </span>{user?.name}</p>
             <span>{(user?.role === 'admin' )? 'Administrador' : (user?.role === 'super_admin') ? 'Master Admin' : '' }</span>
         </div>
        <User/> 
        </div>    
        </Container> */}
        <Container className='admin_dasboard_body'>
       <Row>
       
         {
           user?.role === 'super_admin' ?
           <Col md={4} sm={6} xl={4} className='admindatos'>
            <Container className='Card_ganancias'>
           <div className="ganancias">
           <Caja/>    
           </div>   
           <p>Ganancias totales</p>
           {(eventos?.length === 0 )? 
           <h1>{0}</h1> : 
           (eventos?.length === 1) ? <PrimerGanancia eventos={eventos}/> : 
           (eventos?.length >= 2) ? <Ganancias eventos={eventos}/> : 0} 
           </Container>
          </Col>
        : ''
         }
      
       
       <Col md={4} sm={6} xl={4} className='admindatos'>
       <Container className='Card_datos'>
           <div className="ganancias">
           <Calendario/>    
           </div>   
           <p>Eventos</p>
           <h1>{eventos?.length ?? '0'}</h1>
       </Container>
       </Col>
       <Col md={4} sm={6} xl={4} className='admindatos'>
       <Container className='Card_users'>
           <div className="ganancias">
           <Usuarios/>    
           </div>   
           <p>Usuarios</p>
           <h1> {users?.length}{/* <UsersTotal users={users}/> */}</h1>
       </Container>
       </Col>
       <Col md={6} sm={6} xl={6} className='admindatos'>
       <Container className='Card_admins'>  
           <h5>Administradores</h5>
           <AdminsFuncion 
           users={users}
           auth={auth}
           />
       </Container>
       </Col>
       <FechasHoy
       eventos={eventos} 
       formatDay={formatDay}
       setShow={setShow} 
       setModalTitle={setModalTitle}
       setModalContent={setModalContent}
       setReload={setReload}
       user={user}
       auth={auth}
       />
       <FechasProximas
       eventos={eventos} 
       formatDay={formatDay}
       setShow={setShow} 
       setModalTitle={setModalTitle}
       setModalContent={setModalContent}
       setReload={setReload}
       user={user}
       auth={auth}
       />
       <FechasTotales 
       eventos={eventos} 
       formatMes={formatMes}
       setShow={setShow} 
       setModalTitle={setModalTitle}
       setModalContent={setModalContent}
       setReload={setReload}
       user={user}
       auth={auth}
       />
       </Row>
        </Container>
        </Container>
        <Modal
        show={show}
        setShow={setShow}
        title={modalTitle}
        body={modalContent}
        /> 
        </>
    );
}

const AdminsFuncion = ({users, auth}) => {
   
    return (
        <Table responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Lugar</th>
            <th>Eventos</th>
          </tr>
        </thead>
        <tbody>
              {
                users?.filter(item =>{
                    if(item.role !== 'user'  ){
                      
                      return item;
                    
                    }
                    
                  }).map((item =>{
                  return(
                  <tr key={item.uid}>
                  <td className='options_user_name'>{item.name ?? ''} {item.lastname ?? ''}</td>
                  <td>{item.lugar?.sucursal ?? ''}</td>
                   <td className='admin_info_eventos'><EventosAdmin 
                   auth={auth}
                   idAdmin={item.uid}
                   /></td>
                  </tr>
                )}))
              }
            </tbody>
        </Table>
    )
}


const EventosAdmin = ({auth, idAdmin}) => {
  //LLamar eventos    
const [eventos, setEventos] = useState(undefined);
useEffect(() => {
(async () =>{
  const response = await getEventosAdmin(auth?.token, idAdmin);
  setEventos(response.eventoData);
})()
}, [auth]);
  return (
    <div>
     {eventos ? eventos?.length : 0 } 
    </div>
  );
}

const Ganancias = ({eventos}) => {
  
  //LLamar eventos  
  
  
  const totales = eventos?.map((item) => {
    // Filtrar elementos en item.abonos que no sean undefined
    const abonosDefinidos = item.abonos?.filter((abono) => abono.abono !== undefined);
  
    // Realizar la suma solo en los elementos filtrados
    const sumaAbonos = abonosDefinidos?.reduce(
      (prev, next) => prev + parseFloat(next.abono),
      0
    );
  
    return sumaAbonos || 0; // Retornar la suma o 0 si no hay elementos
  });

  const adds = eventos?.map((item) => {
  // Filtrar elementos en item.adicionales donde el campo total no sea undefined
  const adicionalesDefinidos = item.adicionales?.filter(
    (adicional) => adicional.total !== undefined
  );

  // Realizar la suma solo en los elementos filtrados
  const sumaAdicionales = adicionalesDefinidos?.reduce(
    (prev, next) => prev + next.total,
    0
  );

  return sumaAdicionales || 0; // Retornar la suma o 0 si no hay elementos
});

  

  const sumaAdicionales = adds.reduce((prev, next) => prev  + next , 0);

  const sumaabonos = totales.reduce((prev, next) => parseFloat(prev)  + parseFloat(next) , 0);

 const granTotal = sumaAdicionales === 0 ? sumaabonos + sumaAdicionales : sumaabonos;
 
  /* console.log(granTotal) */

  return (
    <>
     <h1>$ {granTotal  ? Intl.NumberFormat('es-MX').format(granTotal)  : 0}</h1>
    </>
  );
}



const PrimerGanancia = ({eventos}) => {
  const totales = (
    eventos?.map((item) =>{
      return(
        
          item?.abonos?.reduce((prev, next) => parseFloat(prev) + parseFloat(next.abono), 0)
         )})
  )

  const adds = (
    eventos?.map((item) =>{
      return(
         item?.adicionales?.reduce((prev, next) => prev + next.total, 0)
         )})
  )

  const granTotal = adds === 0 ? totales + adds : totales;
  
  return (
    <>
     <h1>$ {totales ? Intl.NumberFormat('es-MX').format(granTotal)  : 0}</h1>
    </>
  );
    
}


const FechasTotales = ({eventos, formatMes,setShow, setModalTitle, setModalContent, setReload, user,auth}) => {
  const mes = formatMes(Date.now())
  return (
    <Col md={6} sm={12} xl={6} >
    <Container className='eventos_card'>
     <h5>Eventos {mes}</h5>
     <Table responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Lugar</th>
            <th>Turno</th>
            <th>Fecha</th>
            <th>Adeudo</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
     {
        eventos?.filter(item =>{
          if(formatMes((Date.parse(item.fecha)- 24*60*60*1000)) === mes ){
            
            return item;
          
          }
          
        }).map((item) =>{
         return(
                  <tr key={item.uid}>
                  <td className='options_user_name'>{item.name ?? ''} {item.lastname ?? ''}</td>
                  <td>{item.lugar?.sucursal ?? ''}</td>
                   <td>{item.turno}</td>
                   <td>{item.fecha}</td>
                   <td><Adeudo abonos={item.abonos} total={item.precioTotal}/></td>
                   <td className='opciones'>
                     <VisualizarEvento
                     evento={item}
                     setShow={setShow}
                     setModalTitle={setModalTitle}
                     setModalContent={setModalContent}
                     setReload={setReload}
                     user={user}
                     auth={auth}
                     />
                   </td>
                  </tr>
              
            )
                  })
      }
      </tbody>
      </Table>
    </Container>
    </Col>
  );
}

const FechasHoy = ({eventos, formatDay,setShow, setModalTitle, setModalContent, setReload, user, auth}) => {
 
  const day = formatDay(Date.now())
  return (
    <Col md={6} sm={12} xl={6} >
    <Container className='eventos_card'>
     <h5>Eventos {day}</h5>
     <Table responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Lugar</th>
            <th>Turno</th>
            <th>Adeudo</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
     {
        eventos?.filter(item =>{
          if(formatDay((new Date(item.fecha))) === day ){
            
            return item;
          
          }
          
        }).map((item) =>{
         return(
                  <tr key={item._id}>
                  <td className='options_user_name'>{item.name ?? ''} {item.lastname ?? ''}</td>
                  <td>{item.lugar?.sucursal ?? ''}</td>
                   <td>{item.turno}</td>
                   <td><Adeudo abonos={item.abonos} total={item.precioTotal}/></td>
                   <td className='opciones'>
                     <VisualizarEvento
                     evento={item}
                     setShow={setShow}
                     setModalTitle={setModalTitle}
                     setModalContent={setModalContent}
                     setReload={setReload}
                     user={user}
                     auth={auth}
                     />
                   </td>
                  </tr>
              
            )
                  })
      }
      </tbody>
      </Table>
    </Container>
    </Col>
  );
}


const FechasProximas = ({eventos, formatDay,setShow, setModalTitle, setModalContent, setReload, user, auth}) => {
 
  return (
    <Col md={6} sm={12} xl={6} >
    <Container className='eventos_card'>
     <h5>Eventos próximos 15 días</h5>
     <Table responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Lugar</th>
            <th>Turno</th>
            <th>Fecha</th>
            <th>Adeudo</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
     {
        eventos?.filter(item =>{
          var fecha1 = moment((new Date(item.fecha)));
          var fecha2 = moment(Date.now())
          if(fecha1.diff(fecha2, 'days') <=15 && fecha1.diff(fecha2, 'days') >=0 ){
            
            return item;
          
          }
          
        }).map((item) =>{ 
         return(
                  <tr key={item.uid}>
                  <td className='options_user_name'>{item.name ?? ''} {item.lastname ?? ''}</td>
                  <td>{item.lugar?.sucursal ?? ''}</td>
                   <td>{item.turno}</td>
                   <td>{item.fecha}</td>
                   <td><Adeudo abonos={item.abonos} total={item.precioTotal}/></td>
                   <td className='opciones'>
                     <VisualizarEvento
                     evento={item}
                     setShow={setShow}
                     setModalTitle={setModalTitle}
                     setModalContent={setModalContent}
                     setReload={setReload}
                     user={user}
                     auth={auth}
                     />
                   </td>
                  </tr>
              
            )
                  })
      }
      </tbody>
      </Table>
    </Container>
    </Col>
  );
}



const VisualizarEvento = ({evento, setShow, setModalTitle, setModalContent, user, auth,}) => {
  
  const editUser = (evento, user, auth) =>{
    setShow(true);
    setModalTitle(`${evento?.name}`)
    setModalContent(<VerEvento eventos={evento} user={user} auth={auth}/>)
  }
  return (
      <>
    <Eye onClick={()=>editUser(evento, user, auth)}/>
    <p>Visualizar</p>  
      </>
    
  );
}



const Adeudo =({abonos, total}) =>{

  
const sumaabonos = abonos.reduce((prev, next) => parseFloat(prev) + parseFloat(next.abono), 0);

const adeudo = total - sumaabonos;
return(
    adeudo === 0 ? <Activo texto={'Liquidado'}/> :
    <span className='adeudo'>$
 {Intl.NumberFormat('es-MX').format(adeudo)   }
    </span>
  

)

}



const UsersTotal = ({users}) => {
  return (
    <>
      {
                
                users?.filter(item =>{
                    if(item.role === 'user'  ){
                      /* console.log(item) */
                      return item;
                    
                    }
                    
                  }).map((item =>{
                  return(
                  <h1 key={item?.uid}>{item?.length}</h1>
                )}))
              }
    </>
  );
}




export default Principal;

