import React,{useState, useEffect} from 'react';
import VisualizadorImagenes from '../../../Components/VisualizadorImagenes';
import {GetSlidesApi, DeleteSlideApi, SignUpSlideApi} from '../../../api/slide';
import useAuth from '../../../hooks/useAuth';


export default function Slide() {
    const {auth} = useAuth();
    const [galeria, setGaleria] = useState(null);
    const [reload, setReload] = useState(false);
useEffect(() => {
    (async () =>{
      const response = await GetSlidesApi();
      setGaleria(response.slide);
      setReload(false);
    })()
  }, [auth,reload]);
  return (
    <VisualizadorImagenes 
    titulo={'Slide'}
    galeria={galeria}
    reload={reload}
    setReload={setReload}
    setGaleria={setGaleria}
    addFunction={SignUpSlideApi}
    deleteFunction={DeleteSlideApi}
    slide={true}
    />
  )
}
