import React, {useState, useEffect} from 'react';
import {getPaquetesApi} from '../../../api/paquetes';
import {Form, Col, Row, Button, Spinner} from 'react-bootstrap';
import {visualizarFecha} from '../../../api/eventos';
import {ReactComponent as Search} from "../../../assets/svg/search.svg";
import {toast} from 'react-toastify';
import './AddNameEvent.scss';

export default function AddNameEvent({lugar,inputs,setInputs}) {
  const [paquetes, setPaquetes] = useState(undefined);
  const [loading, setLoading] = useState(false);
  
 
  const cotizar = async e =>{

    const DataEvento ={
      fecha: inputs.fecha.replace(/-/g,'/'),
      turno:inputs.turno,
      lugar:lugar
    }

    console.log(DataEvento)

    if(inputs.fecha=== '' || inputs.turno === ''){
      e.preventDefault();
      toast.error("Debe de ingresar fecha y turno del evento.")
      return; 
    }  
  
    e.preventDefault();  
  
      setLoading(true)
       const res = await visualizarFecha(DataEvento);

       if(res.ok ===false){
        toast.error(res.message)
     }else{
        toast.success(res.message);  
     }
     setLoading(false);
     e.preventDefault();   
    
  }
  

  useEffect(() => {
    (async () =>{
      const response = await getPaquetesApi(lugar);
      setPaquetes(response.paquetes);
    })()
  }, [lugar]); 

 
  return (
    <div className="formulario_eventos">
    <Row>
      <Col md={8} sm={12} xl={8}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Nombre del evento</Form.Label>
    <Form.Control 
    type="text" 
    placeholder="Nombre del evento"
    name="name"
    value={inputs.name}
    onChange={e => setInputs({...inputs, name: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={4} sm={12} xl={4}>
        <Form.Group className="mb-3"> 
        <Form.Label>Fecha del evento</Form.Label>
        <Form.Control 
    type="date" 
    placeholder="Seleccione una fecha"
    name="fecha"
    value={inputs.fecha}
    onChange={e => setInputs({...inputs, fecha: e.target.value})}
    />
   </Form.Group>
        </Col> 
      <Col md={4} sm={12} xl={4}>
        <Form.Group className="mb-3">
        <Form.Label>Turno</Form.Label> 
    <Form.Select
    placeholder="Selecciona un Turno"
    onChange={e => setInputs({...inputs, turno: e.target.value})}
    value={inputs.turno}
    >
      <option value="">Selecciona un turno</option>
      <option value="Matutino">Matutino</option>
      <option value="Vespertino">Vespertino</option>
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
        <Form.Label>Paquete</Form.Label>
        <Form.Group className="mb-3">
    <Form.Select
    placeholder="Selecciona un paquete"
    onChange={e => setInputs({...inputs, paquete: e.target.value})}
    value={inputs.paquete}
    >
      <option value="">Selecciona un paquete</option>
      {
        paquetes?.map((item) =>{
         return(
          <option key={item._id} value={item.name}>{item.name}</option>
            )
                  })
      }
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={2} sm={12} xl={2}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Hora de entrada</Form.Label>
    <Form.Control 
    type="time" 
    placeholder="Hora de entrada"
    name="entrada"
    value={inputs.entrada}
    onChange={e => setInputs({...inputs, entrada: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={2} sm={12} xl={2}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Hora de salida</Form.Label>
    <Form.Control 
    type="time" 
    placeholder="Hora de salida"
    name="salida"
    value={inputs.salida}
    onChange={e => setInputs({...inputs, salida: e.target.value})}
    />
    </Form.Group>
      </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
        <Form.Label>Personas</Form.Label>
    <Form.Select
    placeholder="Selecciona un número de personas"
    onChange={e => setInputs({...inputs, precioPaquete: e.target.value})}
    value={inputs.precioPaquete}
    >
      <option value="">Selecciona número de personas</option>
      {
        paquetes?.filter(item =>{
          if(item.name === inputs.paquete  ){
            
            return item;
          
          }
          
        }).map((item) =>{
         return(
           <>
          <option  value={item.basico}>50 a 100 personas</option>
          <option  value={item.grande}>101 a 150 personas</option>
          </>
            )
                  })
      }
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Numero de personas</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Numero de personas que asistirán al evento"
    name="personas"
    value={inputs.personas}
    onChange={e => setInputs({...inputs, personas: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Button 
     type="submit" 
     disabled={loading} 
     onClick={cotizar}
     className='btn_evento'>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      <p>Consultar Fecha</p> 
      <Search/> 
      </Button> 
 </Row>  
</div>
    
  )
}
