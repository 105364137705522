import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Button, Table} from 'react-bootstrap';
import Logo from '../../assets/png/logo.png';
import {useParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {getEventos} from '../../api/eventos'; 
import {getLUserDataApi} from '../../api/user'; 
import {ReactComponent as Impresora} from "../../assets/svg/print-solid.svg";
import moment from 'moment';
import 'moment/locale/es-mx'
/* import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; */
import './ContratoPDF.scss';




export default function ContratoPDF() {
    const params = useParams();
    const {auth} = useAuth();
    const [reload, setReload] = useState(false);
    const [eventos, setEventos] = useState(undefined);
    const date1 = "DD-MM-YYYY";
 
        //Llama todos los usuario  
useEffect(() => {
    (async () =>{
      const response = await getEventos(auth?.token, params.id);
      setEventos(response.eventoData);
      setReload(false)
    })()
  }, [auth, reload]);

 
    return (
      <Container className='contrato_container'>
        <TopPagina eventos={eventos} date1={date1}/>
        <BodyPrimerPagina eventos={eventos} auth={auth} date1={date1}/>
        <BodySegundaPagina eventos={eventos} />
        <Button onClick={()=> window.print()}><Impresora/> Imprimir</Button>
        </Container> 

        
      )
    }
    
    const TopPagina =({eventos, date1 }) =>{
      
     /*  console.log(eventos) */
        return(
            <>
            <Row>
            <Col md={3} sm={3} xl={3} xs={3} className="logo_container">
              <img src={Logo} alt="" />
            </Col>
            <Col md={9} sm={9} xl={9} xs={9}> 
              <Row>
              <Col md={12} sm={12} xl={12} xs={12}>
              <p>Nombre del evento: <span>{eventos?.name ? eventos?.name : 'Agrega el nombre del evento'}</span></p>
              </Col> 
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Paquete: <span>{eventos?.paquete ? eventos?.paquete : 'No seleccionaste ningún paquete'}</span></p>
              </Col> 
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Turno: <span>{eventos?.turno ? eventos?.turno : 'No seleccionaste ningún turno'}</span></p>
              </Col>  
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Personas: <span>{eventos?.personas ? eventos?.personas : 'No agregaste numero de personas'}</span></p>
              </Col> 
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Fecha del evento: <span>{ eventos?.fecha ? moment(eventos?.fecha).format(date1)
              /*  Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.parse(eventos?.fecha))   */ 
               : 'No agregaste fecha del evento'}</span></p>
              </Col>
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Inicio del evento: <span>{eventos?.entrada ?  `${eventos?.entrada} Hrs.` : ''}</span></p>
              </Col>
              <Col md={6} sm={6} xl={6} xs={6}>
              <p>Hora de termino: <span>{eventos?.salida ? `${eventos?.salida} Hrs.` : ''}</span></p>
              </Col> 
              </Row>
            </Col>
          </Row>
          <LugarData lugar={eventos?.lugar}/>
          <Row>
          <Col md={9} sm={9} xl={9} xs={9} className='admindatos'>
              <CreadorData creador={eventos?.creador}/>
              </Col> 
              <Col md={3} sm={3} xl={3} xs={3} className="total">
              <h5>Total: <span>${eventos?.precioTotal ? Intl.NumberFormat('es-MX').format(eventos?.precioTotal) : 0}</span></h5>
          </Col>   
          </Row>
          <hr />
          </>
        )
        }

    const BodyPrimerPagina =({eventos, auth, date1}) =>{
      
            return(
                <>
        <PagosData abonos={eventos?.abonos} auth={auth} date1={date1}/>
         {eventos?.adicionales.length >0 ? <ExtrasData adicionales={eventos?.adicionales}/> : ''}
               </>
            )
            }  
    const BodySegundaPagina =({eventos}) =>{
      
            return(
                <>
        {
     (eventos?.guisado !== '' || eventos?.guisado1 !== '' || eventos?.guisado2 !== '' || eventos?.guisado3 !== '' || eventos?.guisado4 !== '' || eventos?.infantil !=='') ?
    <MenuData 
    guisado={eventos?.guisado}
    guisado1={eventos?.guisado1}
    guisado2={eventos?.guisado2}
    guisado3={eventos?.guisado3}
    guisado4={eventos?.guisado4}
    infantil={eventos?.infantil} />
     
    :
    ''
    }
    <PastelData eventos={eventos}/>
               </>
            )
            }             

    const CreadorData =({creador}) =>{
      
            return(
                <>
                <Col md={9} sm={9} xl={9} className='admindatos'>
            <p>Vendedor: <span>{creador?.name ? creador?.name : ''}  {creador?.lastname ? creador?.lastname : ''}</span></p>
                </Col>
                </>
            )
            } 

    const LugarData =({lugar}) =>{
      
                return(
                    <Row>
                    <Col md={12} sm={12} xl={12}>
                    <p>Lugar del evento: <span>{lugar?.sucursal ? lugar?.sucursal : 'No se agrego el lugar del evento'}</span></p>  
                    </Col> 
                    </Row>
                )
      
            }            

     const PagosData =({abonos, auth, date1}) =>{
   
        return(
            <>
            <div className='pagos_container'>
            <h3>Pagos</h3>
            <Table  bordered >
      <thead>
        <tr>
          <th>Abono</th>
          <th>Fecha</th>
          <th>Método de pago</th>
          <th>Recibió</th>
        </tr>
      </thead>
      <tbody>
      {
             abonos?.filter((item) => item.abono !== undefined).map((item) =>{
                return(
                     <tr className='adminDatos_container' key={item._id}>
                    <td className='admindatos'>
                    <p><span>${item?.abono ? Intl.NumberFormat('es-MX').format(item?.abono)  : 0}</span> </p>
                     </td>
                    <td className='admindatos'>
                    <p><span>{item?.fechadePago ? moment(item?.fechadePago).format(date1) : 'No se agrego fecha'}</span> </p>
                     </td> 
                     <td className='admindatos'>
                     <p><span>{item.metodo ? item.metodo : 'No seleccionaste ningúna forma de pago'}</span></p>
                    </td> 
                    <td className='admindatos'>
                     <RecibePago user={item.recibio} auth={auth}/>
                    </td> 
                     </tr>
                )
              })   
            }
      </tbody>
    </Table>
            </div>
            </>
            
        )
        }
                    
        const ExtrasData =({adicionales}) =>{
                      
            return(
             <>
        <h3>Extras</h3>
        <Table  bordered >
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Cantidad</th>
        </tr>
      </thead>
      <tbody>
      {
             adicionales?.map((item) =>{
                return(
                     <tr className='adminDatos_container' key={item._id}>
                    <td className='admindatos'>
                    <p>{item?.nombre ? item?.nombre   : 'No se agrego un nombre'} </p>
                     </td>
                    <td className='admindatos'>
                    <p>{item?.piezas ? item?.piezas   : 'No se agrego número'} </p>
                     </td> 
                     </tr>
                )
              })   
            }
      </tbody>
    </Table>
             </>
                
            
             )
        }
    
    const MenuData =({guisado,guisado1,guisado2,guisado3,guisado4,infantil }) =>{
      const guisadosArray = [guisado, guisado1, guisado2, guisado3, guisado4];
      return (
        <Row>
          {guisadosArray.length > 0 && (
            <Col md={12} sm={12} xl={12}>
              <h3>Menú</h3>
              <Table  bordered >
                <tbody>
                {guisadosArray
                .filter((guisado) => guisado !== "") // Filtrar elementos vacíos
                .map((guisado, index) => (
                  <tr key={index}>
                    <td>{guisado}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          )}
    
          {
          infantil && (
            <Col md={12} sm={12} xl={12}>
               <h3>Menú infantil</h3>
              <Table  bordered >
                <tbody>
                    <tr>
                      <td>Menú infantil</td>
                      <td>{infantil}</td>
                    </tr>
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      );
    }; 
    
    const PastelData = ({ eventos }) => {
      // Crear un array de los datos utilizados en el componente
      const data = [
        { label: 'Pastel', value: eventos?.pastel },
        { label: 'Piñata', value: eventos?.piñata },
        { label: 'Refrescos', value: eventos?.refrescos },
        { label: 'Agua', value: eventos?.agua },
        { label: 'Dulceros', value: eventos?.dulceros },
        { label: 'Show', value: eventos?.show },
      ];
    
      // Filtrar los elementos que no son iguales a ""
      const filteredData = data.filter((item) => item.value !== "");
    
      return (
        <>
          <Row>
            <Col md={12} sm={12} xl={12}>
              <Table  bordered >
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.label}</td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          {eventos?.comentarios && (
            <>
              <h3>Comentarios</h3>
              <Row>
                <Col md={12} sm={12} xl={12} className='comentarios_data'>
                  <p>{eventos?.comentarios}</p>
                </Col>
              </Row>
            </>
          )}
          <UserData user={eventos?.user} />
        </>
      );
    };

const UserData =({user}) =>{
      
            return(
    <Row className='row_firma_usuario'>
    <Col md={2} sm={1} xl={4} xs={1}>
      </Col>  
      <Col md={8} sm={10} xl={4} xs={10} className='firma_usuario'>
       
       <p>{user?.name ? user?.name : ''} {user?.lastname ? user?.lastname : ''}</p>  
      </Col>  
      <Col md={2} sm={1} xl={4} xs={1}>
      </Col>  
    </Row>
            )
            }
 
            const RecibePago = ({user, auth}) => {
              const [users, setUsers] = useState(undefined);
             useEffect(() => {
                (async () =>{
                  const response = await getLUserDataApi(auth.token, user);
                  setUsers(response.userData);
                })()
              }, [auth]);
              return (
                <p>{users?.name ?? ''} {users?.lastname}</p>
              
              );
            }
            