import React,{useState, useEffect} from 'react';
import {visualizarFecha} from '../../../api/eventos';
import {Form, Button, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import './Cotizador.scss';
export default function Cotizador({idLugar}) {

  const [reload, setReload] = useState(false);
const [loading, setLoading] = useState(false);

  
  const [inputs, setInputs] = useState({
    turno:"",
    fecha:"",
});
const dataNew ={
  lugar:idLugar,
    turno:inputs.turno,
    fecha:inputs.fecha.replace(/-/g,'/'),
}
const register = async e =>{

  if(inputs.fecha=== '' || inputs.turno === ''){
    e.preventDefault();
    toast.error("Complete todos los campos. ")
    return; 
  }  

  e.preventDefault();  

    setLoading(true)
     const res = await visualizarFecha(dataNew);
     if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setReload(true);   
   }
   setLoading(false);
   e.preventDefault(); 
  
  
}


  return (
    <div className='banner__container_locate'>
    <div className="form_container_cotizador" id='Disponibilidad'>
      <h3>Verifica disponibilidad para tu evento</h3>
      <Form>
     <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Fecha del evento</Form.Label>
    <Form.Control 
    type="date" 
    placeholder="fecha del evento"
    name="fecha"
    value={inputs.fecha}
    onChange={e => setInputs({...inputs, fecha: e.target.value})}
    />
  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Horario</Form.Label>
    <Form.Select
    placeholder="Selecciona un Turno"
    onChange={e => setInputs({...inputs, turno: e.target.value})}
    value={inputs.turno}
    >
      <option value="">Selecciona un turno</option>
      <option value="Matutino">Matutino</option>
      <option value="Vespertino">Vespertino</option>
    </Form.Select>
  </Form.Group>
  <Button 
     type="submit" 
     disabled={loading} 
     onClick={register}
     className='btn_evento'>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Consultar
      </Button> 
</Form>
    </div>
    </div>  
  
  );
}
