import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Logo from '../../assets/png/logo.png';
import './ContratoPDF.scss';
import 'moment/locale/es-mx'





export default function ContratoPDF(eventos) {
  
 
    return (
      <Container className='contrato_container'>
        <TopPagina eventos={eventos?.eventos}/>
        <BodyPrimerPagina eventos={eventos?.eventos}/>
        <BodySegundaPagina eventos={eventos?.eventos} />
        </Container> 

        
      )
    }
    
    const TopPagina =({eventos}) =>{
        return(
            <>
            <Row>
            <Col md={3} sm={3} xl={3}>
              <img src={Logo} alt="" />
            </Col>
            <Col md={9} sm={9} xl={9}> 
              <Row>
              <Col md={12} sm={12} xl={12}>
              <h6>Nombre del evento: <span>{eventos?.name ? eventos?.name : 'Agrega el nombre del evento'}</span></h6>
              </Col> 
              <Col md={6} sm={6} xl={6}>
              <h6>Paquete: <span>{eventos?.paquete ? eventos?.paquete : 'No seleccionaste ningún paquete'}</span></h6>
              </Col> 
              <Col md={6} sm={6} xl={6}>
              <h6>Turno: <span>{eventos?.turno ? eventos?.turno : 'No seleccionaste ningún turno'}</span></h6>
              </Col>  
              <Col md={6} sm={6} xl={6}>
              <h6>Personas: <span>{eventos?.personas ? eventos?.personas : 'No agregaste numero de personas'}</span></h6>
              </Col> 
              <Col md={6} sm={6} xl={6}>
              <h6>Fecha del evento: <span>{ eventos?.fecha ?
               Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.parse(eventos?.fecha))   
               : 'No agregaste fecha del evento'}</span></h6>
              </Col>
              </Row>
            </Col>
          </Row>
          <LugarData lugar={eventos?.lugar}/>
          <Row>
          <Col md={9} sm={9} xl={9} className='admindatos'>
              <CreadorData creador={eventos?.creador}/>
              </Col> 
              <Col md={3} sm={3} xl={3} className="total">
              <h5>Total: <span>${eventos?.precioTotal ? Intl.NumberFormat('es-MX').format(eventos?.precioTotal) : 0}</span></h5>
              </Col>   
          </Row>
          <hr />
          </>
        )
        }

    const BodyPrimerPagina =({eventos}) =>{
      
            return(
                <>
        <PagosData abonos={eventos?.abonos}/>
        <ExtrasData adicionales={eventos?.adicionales}/>
               </>
            )
            }  
    const BodySegundaPagina =({eventos}) =>{
      
            return(
                <>
        {
     (eventos?.guisado !== '' || eventos?.guisado1 !== '' || eventos?.guisado2 !== '' || eventos?.guisado3 !== '' || eventos?.guisado4 !== '' || eventos?.infantil !=='') ?
    <MenuData 
    guisado={eventos?.guisado}
    guisado1={eventos?.guisado1}
    guisado2={eventos?.guisado2}
    guisado3={eventos?.guisado3}
    guisado4={eventos?.guisado4}
    infantil={eventos?.infantil} />
     
    :
    ''
    }
    <PastelData eventos={eventos}/>
               </>
            )
            }             

    const CreadorData =({creador}) =>{
      
            return(
                <>
                <Col md={9} sm={9} xl={9} className='admindatos'>
            <h5>Vendedor: <span>{creador?.name ? creador?.name : ''}  {creador?.lastname ? creador?.lastname : ''}</span></h5>
                </Col>
                </>
            )
            } 

    const LugarData =({lugar}) =>{
      
                return(
                    <Row>
                    <Col md={12} sm={12} xl={12}>
                    <h6>Lugar del evento: <span>{lugar?.sucursal ? lugar?.sucursal : 'No se agrego el lugar del evento'}</span></h6>  
                    </Col> 
                    {/* <Col md={11} sm={11} xl={11}>
                    <h6>Dirección: <span>{lugar?.direccion ? lugar?.direccion : 'No se agrego una dirección del lugar'}</span></h6>  
                    </Col>  */}   
                    </Row>
                )
      
            }            

     const PagosData =({abonos}) =>{
  
        return(
            <>
            <Row className='Pagos_container'>
            <h3>Pagos</h3>
            <Col md={4} sm={4} xl={4}>
            <h5 className='titulo_lista'>Abono</h5>
            </Col>
            <Col md={4} sm={4} xl={4}>
            <h5 className='titulo_lista'>Fecha</h5>    
            </Col>
            <Col md={4} sm={4} xl={4}>
             <h5 className='titulo_lista'>Método de pago </h5>   
            </Col>
            {
  abonos
    ?.filter((item) => item.abono !== undefined) // Filtrar elementos con campo abono definido
    .map((item) => (
      <>
        <Col md={4} sm={4} xl={4} className='admindatos'>
          <p>
            <span>
              ${item.abono
                ? Intl.NumberFormat('es-MX').format(item.abono)
                : 0}
            </span>{' '}
          </p>
        </Col>
        <Col md={4} sm={4} xl={4} className='admindatos'>
          <p>
            <span>
              {item.fechadePago
                ? Intl.DateTimeFormat('es-MX', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(Date.parse(item.fechadePago))
                : 'No se agregó fecha'}
            </span>{' '}
          </p>
        </Col>
        <Col md={4} sm={4} xl={4}>
          <h6>
            <span>
              {item.metodo
                ? item.metodo
                : 'No seleccionaste ninguna forma de pago'}
            </span>
          </h6>
        </Col>
      </>
    ))
}

            </Row>
            </>
            
        )
        }
                    
        const ExtrasData =({adicionales}) =>{
                      
            return(
             <>
             <Row className='adicionales_container'>
        <h3>Extras</h3>
            {
            adicionales?.map((item =>{
                 return(
                <>
                <Col md={9} sm={9} xl={9} className='admindatos'>
                <p>Nombre: <span>{item?.nombre ? item?.nombre   : 'No se agrego un nombre'}</span> </p>
                </Col>
                <Col md={3} sm={3} xl={3} className='admindatos'>
                <p>Cantidad: <span>{item?.piezas ? item?.piezas   : 'No se agrego número'}</span> </p>
                </Col>
                </>
            )}))    
            }
            </Row>
             </>
                 
            
             )
        }
    
    const MenuData =({guisado,guisado1,guisado2,guisado3,guisado4,infantil }) =>{
      
        return(
          <Row>
            {
              guisado !=='' ||guisado1 !== '' || guisado2 !=='' || guisado3 !== '' || guisado4 !== ''?
              <>
              <h3>Menú</h3>
            <Col md={12} sm={12} xl={12} >
           {guisado ? <p> <span>Guisado 1:{guisado}</span> </p>: '' }
            </Col>
            <Col md={12} sm={12} xl={12} >
           {guisado1 ? <p> <span>Guisado 2:{guisado1}</span> </p>: '' }
            </Col>
            <Col md={12} sm={12} xl={12} >
           {guisado2 ? <p> <span>Guisado 3:{guisado2}</span> </p>: '' }
            </Col>
            <Col md={12} sm={12} xl={12} >
           {guisado3 ? <p> <span>Guisado 4:{guisado3}</span> </p>: '' }
            </Col>
            <Col md={12} sm={12} xl={12} >
           {guisado4 ? <p> <span>Guisado 5:{guisado4}</span> </p>: '' }
            </Col>
            </>
            : ''
            }
            {
              infantil ?
              <>
              <h3>Menú infantil</h3>
              <Col md={12} sm={12} xl={12} >
           <p>Menu infantil: <span>{infantil}</span> </p>
            </Col>
              </>
              : ''
            }
            
          </Row>
        )
        }  
    
const PastelData =({eventos}) =>{
      
return(
    <>
    <Row>
    {
      eventos?.pastel ?
      <Col md={12} sm={12} xl={12}>
        <p>Pastel: <span>{eventos?.pastel}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.piñata ?
      <Col md={12} sm={12} xl={12}>
        <p>Piñata: <span>{eventos?.piñata}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.refrescos ?
      <Col md={12} sm={12} xl={12}>
        <p>Refrescos: <span>{eventos?.refrescos}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.agua ?
      <Col md={12} sm={12} xl={12}>
        <p>Agua: <span>{eventos?.agua}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.dulceros ?
      <Col md={12} sm={12} xl={12}>
        <p>Dulceros: <span>{eventos?.dulceros}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.show ?
      <Col md={12} sm={12} xl={12}>
        <p>Show: <span>{eventos?.show}</span></p>
      </Col>
      : '' 
    }
    {
      eventos?.comentarios ?
      <>
      <h3>Comentarios</h3>
      <Row>
      <Col md={12} sm={12} xl={12} className='comentarios_data'>
        <p>{eventos?.comentarios}</p>
      </Col>
      </Row>
      
      </>
      : '' 
    }
    </Row>
    </>
  )
      
            }    
