import React, {useState, useEffect} from 'react';
import { Form, Col, Row, Button, Spinner} from 'react-bootstrap';
import {updateDateEvent} from '../../api/eventos'; 
import moment from 'moment';
import {toast} from 'react-toastify';
import 'moment/locale/es-mx'

export default function EditDate({setReload, setShow, token, data, reload}) {
    const [updateEvento, setUpdateEvento] = useState({});
    const date1 = "YYYY-MM-DD";
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUpdateEvento({ 
         fecha: moment(data?.fecha).format(date1),
         turno:data?.turno,
         lugar:data?.lugar?._id,
        })
       }, [data])

       const update = async e =>{

       /*  if( updateEvento.fecha <= moment().format(date1) ){
          e.preventDefault();
          toast.error("No puedes seleccionar una fecha menor a la del día de hoy.")
          return; 
        } */
    
        e.preventDefault();  
      
          setLoading(true)
           const res = await updateDateEvent(token, updateEvento, data?.uid);
           if(res.ok ===false){
            toast.error(res.message)
         }else{
            toast.success(res.message);
              setReload(true);   
              setShow(false);
         }
         setLoading(false);
         e.preventDefault(); 
        
        
      }

  return (
    <Form onSubmit={update} className='formulario'>
      <Row>
      <Col md={12} sm={12} xl={12}>
        <Form.Group className="mb-3">
      <Form.Control 
    type="date" 
    placeholder="Seleccione una fecha"
    name="fecha"
    style={{width:'100%'}}
    value={updateEvento.fecha}
    onChange={e => setUpdateEvento({...updateEvento, fecha: e.target.value})}
    />
   </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" className='Button_actualizar' type="submit" disabled={loading}>
    {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Actualizar
    </Button>
      </Form>
  )
}
