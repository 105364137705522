import React from 'react';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import './DeleteUser.scss';

 
const Deleteuser = ({id, setShow, setReload, token, titulo, deleteFunction}) => {
    
    const deleteUser = () =>{
        deleteFunction(token,  id).then(res =>{
      
            if(res.ok === true){
              toast.success(res.message);
              setShow(false);
              setReload(true);        
         }else{
            toast.error(res.message)
         }
            })
    }
    return (
        <div className='delete_user_form'>
           <p>¿Estas seguro de querer eliminar a <span>{`${titulo}`}</span> </p> 
           <div className="buttons">
            <Button onClick={() => setShow(false)}>Cancelar</Button>
            <Button variant="danger" onClick={deleteUser}>Eliminar</Button>   
           </div>
            
        </div>
    );
}

export default Deleteuser;
