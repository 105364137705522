import React,{useState, useEffect} from 'react';
import {Container, Carousel, Col, Row} from 'react-bootstrap';
import {GetSlidesApi} from '../../../api/slide';
import Slide1 from '../../../assets/jpg/image1.jpg';
import Slide2 from '../../../assets/jpg/image2.jpg';
import Slide3 from '../../../assets/jpg/image4.jpg';
import './Paquetes.scss';

const Paquetes = () => {
    const [galeria, setGaleria] = useState(null);
  
    useEffect(() => {
      (async () =>{
        const response = await GetSlidesApi();
        setGaleria(response.slide);
      })()
    }, []); 
    return (
        <Container className='sorprendido' id='Promociones'>
            <h1>PROMOCIONES</h1> 
          {
            (galeria?.length === 0 || galeria === undefined)?
           <div className="no_data">
            <h5>¡Descubre nuestras emocionantes promociones en el establecimiento! Estamos encantados de ofrecerte una variedad de ofertas especiales y descuentos exclusivos. ¡No te pierdas la oportunidad de aprovechar nuestras increíbles promociones! Visítanos y nuestro amable personal estará encantado de brindarte más información. ¡Te esperamos con grandes sorpresas y oportunidades para disfrutar al máximo tu experiencia en nuestro establecimiento!</h5>
           <Row>
            <Col md={4} sm={12} xl={4} className='card_image'> 
                <div className="content"></div> 
                <img src={Slide1} alt="" />
            </Col>
            <Col md={4} sm={12} xl={4} className='card_image'>
            <div className="content"></div>
                <img src={Slide2} alt="" /> 
            </Col>
            <Col md={4} sm={12} xl={4} className='card_image'>
            <div className="content"></div>
                <img src={Slide3} alt="" />
            </Col>
           </Row>
           
           </div> :
          <Carousel>
            {
          galeria?.map((item) =>{
            return(
              <Carousel.Item>
                <img
                  src={item?.imagen}
                  className='slide__img'
                  alt="First slide"
                />
              </Carousel.Item>
            )
          })
        }
          </Carousel>
          }  
        </Container> 
        
    );
}

export default Paquetes;
