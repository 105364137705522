import React,{useState} from 'react';
import './ResetPassword.scss';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import {resetPassword} from '../../api/user'
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

export default function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
         initialValues: initialValues(),
         validationSchema: Yup.object().shape(validationSchema()),
        onSubmit:async (formData)=> {
          setLoading(true)
         const res = await resetPassword(formData);
   
         if(res.ok ===false){
         toast.error(res.message)
      }else{
         toast.success(res.message);
      }
      setLoading(false)
      resetForm();
        }
       })
   
       const resetForm = () =>{
        
            formik.values.email=""
    
    }

  return (
    <div className='container_resetPasword'>
        <Container>
            <Row>
            <Col md={4} sm={0} xl={4} xs={0}></Col>
            <Col md={4} sm={0} xl={4} xs={12} className='container_form'>
            <h1>Escribe un E-mail</h1>
<Form onSubmit={formik.handleSubmit}>
        <Row>
        <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="emali" 
      placeholder="E-mail"
      name='email'
      value={formik.values.email}
      onChange={formik.handleChange}
      isInvalid={formik.errors.email}
      isValid={!formik.errors.email}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Enviar
      </Button>    
        </Col>
      
      </Row>   
  </Form> 
            </Col>
            <Col md={4} sm={0} xl={4} xs={0}></Col>    
            </Row>
        
    </Container>
    </div>
  )
}

function initialValues(){
    return {
      email:""
    }
  }
  function validationSchema(){
    return{
      email: Yup.string().email("El email no es valido"),
    };
  }