import React from 'react';
import './Activo.scss';

const Activo = ({texto}) => {
    return (
        <div className='activo'>
           <p>{texto}</p> 
        </div>
    )
}

export default Activo;