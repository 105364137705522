import React, {useState} from 'react';
import {Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import {createDescripcionApi} from '../../api/paquetes'
import './EditUserForm.scss';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({setReload, token, name, idpaquete}) {
  const [loading, setLoading] = useState(false);
 
 const formik = useFormik({
      initialValues: initialValues(),
      validationSchema: Yup.object().shape(validationSchema()),
     onSubmit:async (formData)=> {
       const data ={
          descripciones:[{descripcion: formData?.descripcion}]
       }
      
       
       setLoading(true)
      const res = await createDescripcionApi(data, token, idpaquete, name);

      if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setReload(true);   
   }
   setLoading(false);
     }
    })


  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Descripción"
      name="descripcion"
      value={formik.values.descripcion}
        onChange={formik.handleChange}
        isInvalid={formik.errors.descripcion}
        isValid={!formik.errors.descripcion}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.descripcion}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Agregar Descripción
      </Button>
  </Form>
  );
}

function initialValues(){
  return {
    descripcion:"",
  }
}
function validationSchema(){
  return{
    descripcion: Yup.string().required('Ingrese una descripción', true),
}
}

export default EditUserForm;
