import React, {useState, useEffect} from 'react';
import {getProductosApi, DeleteProductApi} from '../../../api/productos';
import Modal from '../../../Components/ModalNormal';
import {Container, Table,  Button} from 'react-bootstrap';
import {ReactComponent as Add} from "../../../assets/svg/plus-square-regular.svg";
import {ReactComponent as Edit} from "../../../assets/svg/admin/edit-solid.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import NoImage from "../../../assets/png/add-product.png";
import EditPaqueteForm from '../../../Components/EditProductForm';
import AddPaqueteForm from '../../../Components/AddProductoForm';
import DeleteUserData from '../../../Components/DeleteUser';
import useAuth from '../../../hooks/useAuth';
import './Productos.scss'; 
import NoItem from '../../../Components/NoItem';

const Productos = () => {
  const {auth} = useAuth();
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);
  const [paquete, setPaquete] = useState(undefined);
  useEffect(() => {
    (async () =>{
      const response = await getProductosApi();
      setPaquete(response.productos);
      setReload(false);
    })()
  }, [reload]);
    return (
        <div  className='container_productos'>
          <div  className='container_admin_footer_nav'>
     <Container >
<h5>Productos</h5>
<AddPaquete
setShow={setShow} 
setModalTitle={setModalTitle} 
setModalContent={setModalContent} 
setReload={setReload}
token={auth?.token}
/>
     </Container>
   </div>
   <Container fluid className="users_section_data">
    <Table responsive>
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Precio</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            (paquete?.length === 0)?
            <NoItem
            imagen={NoImage}
            texto={'No tienes imágenes en esta galería'}
            />
            :
            paquete?.map((item =>{
              return(
              <tr key={item._id}>
              <td className='options_user_name'>{item.name ?? ''}</td>
              <td><p>${Intl.NumberFormat('es-MX').format(item.precio ) ?? ''}</p></td>
              <td className='options_user_admin'>
                <div className="edit">
                  <EditUser 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  auth={auth.id}
                  />
                </div>
                <div className="delete">
                <DeleteUser 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={DeleteProductApi} 
                  />
                </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
        </div>
    )
}

const AddPaquete = ({ setShow, setModalTitle, setModalContent, setReload, token, auth}) =>{
  const editUser = () =>{
  
      setShow(true);
      setModalTitle('Agregar producto')
      setModalContent(<AddPaqueteForm  
        setReload={setReload} 
        setShow={setShow} 
        token={token} 
        auth={auth}  
        />)
    }
  return(
      <>
      <Button variant="light" onClick={()=>editUser()}><Add/>Agregar</Button>
      </>
      
  )
}

const EditUser = ({data, setShow, setModalTitle, setModalContent, setReload, token, auth}) => {
  
  const editUser = data =>{
    setShow(true);
    setModalTitle(`${data.name}`)
    setModalContent(<EditPaqueteForm userdata={data} setReload={setReload} setShow={setShow} token={token} auth={auth}/>)
  }
  return (
      <>
    <Edit onClick={()=>editUser(data)}/>
    <p>Editar</p>  
      </>
    
  );
}

const DeleteUser = ({data, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction}) => {
  
  const editUser = data =>{
    setShow(true);
    setModalTitle("")
    setModalContent(
    <DeleteUserData 
    id={data?._id} 
    setReload={setReload} 
    setShow={setShow} 
    token={token} 
    titulo={`${data.name}?`}
    deleteFunction={deleteFunction}   
    />)
  }
  return (
      <>
    <Delete onClick={()=>editUser(data)}/>
    <p>Eliminar</p>
    </>
  );
}

export default Productos;