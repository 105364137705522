import React from 'react';
import {Container} from 'react-bootstrap';
import './Videos.scss';

const Videos = () => {
    return (
        <>
        <Container className='conocenos' id='Conocenos'>
                <h1>
                   infla<span>park</span>
                </h1>
            <p>Inflapark es un novedoso concepto de parques indoor que ofrece diversión para todas las edades. Ya sea que vengas con amigos o con tu familia, encontrarás un lugar lleno de emocionantes actividades y juegos extremos. Nuestras instalaciones incluyen una alberca de pelotas, un área para niños, una zona de snacks y estacionamiento para tu comodidad. Además, contamos con un amplio salón de fiestas y muchas otras atracciones. ¡No esperes más, ven y disfruta en Inflapark!</p>

        </Container>
        </>
    )
}

export default Videos;