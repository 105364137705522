import React from 'react';
import "./CarrouselInfo.scss";

function CarrouselInfo() {
  return (
    <div className="carrouselInfo">
      <div className="track">
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
     
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        <h3> #ESTABIENPERRO </h3>
        </div>
    </div>
  );
}

export default CarrouselInfo;
