import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import { Container, Button, Row, Col } from 'react-bootstrap';
import {ReactComponent as Add} from "../../../assets/svg/plus-square-regular.svg";
import {ReactComponent as Check} from "../../../assets/svg/check-solid.svg";
import {ReactComponent as Edit} from "../../../assets/svg/admin/edit-solid.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import {getPaquetesApi,deleteDescripcionApi, deleteDePaqueteApi} from '../../../api/paquetes';
import useAuth from '../../../hooks/useAuth';
import AddPaqueteForm from '../../../Components/AddPaqueteForm';
import EditPaqueteForm from '../../../Components/EditPaqueteForm';
import AddServicio from '../../../Components/AddServicioForm';
import Modal from '../../../Components/ModalNormal';
import NoImage from "../../../assets/png/new-product.png";
import NoItem from '../../../Components/NoItem';
import DeleteUserData from '../../../Components/DeleteUser';
import './Paquetes.scss';

export default function Paquetes() {
  const {auth} = useAuth();
  const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const [paquete, setPaquete] = useState(undefined);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const params = useParams();
   /*  console.log(paquete) */
    const addPaquete = (item) =>{
    
      setShow(true);
      setModalTitle(`Agregar una descripcion para ${item?.name}`)
      setModalContent(
      <AddServicio  
      setReload={setReload}  
      token={auth?.token} 
      name={item?.name} 
      idpaquete={item?._id}/>)
    }
    const deletePaquete = item =>{
      setShow(true);
      setModalTitle("")
      setModalContent(
      <DeleteUserData 
      id={item?._id} 
      setReload={setReload} 
      setShow={setShow} 
      token={auth?.token} 
      titulo={`${item?.name}?`}
      deleteFunction={deleteDePaqueteApi}   
      />)
    }
    const editPaquete = (item) =>{
    
      setShow(true);
      setModalTitle(`Agregar una descripcion para ${item?.name}`)
      setModalContent(
      <EditPaqueteForm  
      setReload={setReload}  
      token={auth?.token} 
      setShow={setShow}
      userdata={item}/>)
    }
    
    useEffect(() => {
      (async () =>{
        const response = await getPaquetesApi(params?.id);
        setPaquete(response.paquetes);
        setReload(false);
      })()
    }, [reload]);

  return (
    <div className="container_paquetes">
      <div  className='container_admin_footer_nav'>
     <Container > 
<h5>Paquetes</h5> 
<AddPaquete
setShow={setShow} 
setModalTitle={setModalTitle} 
setModalContent={setModalContent} 
setReload={setReload}
token={auth?.token}
auth={auth?.id}
idLugar={params?.id}
/>
     </Container>
   </div>
   <Container fluid className="users_section_data">
     {
       (paquete?.length === 0)?
       <NoItem
       imagen={NoImage}
       texto={'Aun no creas ningún paquete para este lugar'}
       />
       :
       <Row>
         {
       paquete?.filter(item =>{
        if(item.tipo === 'paquete'  ){
          
          return item;
        
        }
        
      }).map((item =>{
         
         return(
        <Col md={6} sm={12} xl={4} className='container_paquetes_body'>
          <Container style={{background:item?.color, color:item?.colorTexto}}>
            <div className="funciones_paquetes">
                <Edit id='edit' onClick={()=>editPaquete(item)}/>
                <Delete id='delete' onClick={()=>deletePaquete(item)}/>
            </div>
           <h4>{item?.name}</h4>
           <hr />
          <Info 
          descripcion={item?.descripciones} 
          setReload={setReload} 
          token={auth?.token} 
          setShow={setShow} 
          setModalTitle={setModalTitle} 
          setModalContent={setModalContent}  
          />
          <button onClick={()=>addPaquete(item)}><Add/> Agregar servicio</button>
          </Container>
        </Col>
       )}))
      }
      
       </Row>
        
   
}
   </Container>   
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    />
    </div>
      
  );
}

const Info =({descripcion, setReload, token, setShow, setModalTitle, setModalContent }) =>{
  const deleteDescripcion = item =>{
    setShow(true);
    setModalTitle("")
    setModalContent(
    <DeleteUserData 
    id={item?._id} 
    setReload={setReload} 
    setShow={setShow} 
    token={token} 
    titulo={`${item.descripcion}?`}
    deleteFunction={deleteDescripcionApi}   
    />)
  } 
return(
  descripcion?.map((item =>{
    return(
   <div className="list_descripcion" onClick={()=>deleteDescripcion(item)} /* key={item?.paquete} */>
     <Check/>
    <p>{item?.descripcion}</p>
   </div>
  )}))
)
} 

const AddPaquete = ({ setShow, setModalTitle, setModalContent, setReload, token, auth, idLugar}) =>{
  const editUser = () =>{
  
      setShow(true);
      setModalTitle('Agregar paquete')
      setModalContent(<AddPaqueteForm  
        setReload={setReload} 
        setShow={setShow} 
        token={token} 
        auth={auth}  
        idLugar={idLugar}
        />)
    }
  return(
      <>
      <Button variant="light" onClick={()=>editUser()}><Add/>Agregar</Button>
      </>
      
  )
}

