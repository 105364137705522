import React from 'react'
import { Carousel } from 'react-bootstrap';
import Slide1 from '../../../assets/jpg/1.jpg';
import Slide2 from '../../../assets/jpg/2.jpg';
import Slide3 from '../../../assets/jpg/3.jpg';

function BannerDoggy() {
  return (
    <Carousel data-bs-theme="dark">
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={Slide1}
        alt="First slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={Slide2}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={Slide3}
        alt="Third slide"
      />
    </Carousel.Item>
  </Carousel>
  )
}

export default BannerDoggy