import React from "react";
import { Route, Switch } from "react-router-dom";
import './layoutDoggy.scss';
import Navbar from "../Components/Doggy/NavBar/Navbar";
import { Footer } from "../Components/Doggy/Footer/Footer";
function LayoutDoggy(props) {
  const { routes } = props;
  return (
    <div className="container__doggy">
      <Navbar />
      <LoadRoutes routes={routes} />
      <Footer />
    </div>
  );
}

function LoadRoutes({ routes }) {
  return (
    <Switch>
      {" "}
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}

export default LayoutDoggy;
