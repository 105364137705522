import React, {useState, useEffect} from 'react';
import {getPaquetesApi} from '../../../api/paquetes';
import { Container, Row, Col} from 'react-bootstrap';
import {ReactComponent as Check} from "../../../assets/svg/check-solid.svg";
import {ReactComponent as Add} from "../../../assets/svg/right-solid.svg"; 
import NoItem from '../../../Components/NoItem';
import useCart from '../../../hooks/useCart';

import './Paquetes.scss';

const Paquetes = ({idLugar,name,phone, lugarname}) => {
    
    const [paquete, setPaquete] = useState(undefined);
    useEffect(() => {
        (async () =>{
          const response = await getPaquetesApi(idLugar);
          setPaquete(response.paquetes);
        })()
      }, [idLugar]);
    const {addProductCart} = useCart();  
    return (
        <Container className='container_packages_page' id='Paquetes'>
     {
       (paquete?.length === 0 || paquete === undefined)?
       <div className="noPaquete">
        <h1>Contacta con Inflapark {name ? name : ''} para saber más sobre sus paquetes al teléfono: {phone ? phone : ''}</h1>
     
       </div>
       
       :
       <Row>
         <h1>Nuestros paquetes</h1> 
         {
       paquete?.map((item =>{
         
         return(
        <Col md={6} sm={12} xl={4} className='container_paquetes_body'>
          <Container style={{background:item?.color, color:item?.colorTexto}}>
           <h4>{item?.name}</h4>
           <hr />
           <Info 
          descripcion={item?.descripciones}  
          />
          <a href={`${lugarname}/${item?._id}`} style={{color:item?.color}}>Ver más<Add style={{fill:item?.color}}/> </a> </Container>
        </Col>
       )}))
      }
      
       </Row>
        
   
}
   </Container>   
    )
}

const Info =({descripcion}) =>{ 
  return(
    descripcion?.map((item =>{
      return(
     <div className="list_descripcion">
       <Check/>
      <p>{item?.descripcion}</p>
     </div>
    )}))
  )
  }

export default Paquetes;