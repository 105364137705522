//Crea un usuario
export function crearEvento(data, token) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, message: 'El evento se agrego de forma satisfactoria' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Comprobar disponibilidad de evento
export function visualizarFecha(data) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/fecha`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.fecha) {
                return { ok: true, message: 'Esta fecha está disponible' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Obtener eventos de los usuarios
export function getEventosUser(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

export function getEventos(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/obtener/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

export function getEventosAdmin(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/obtener/admin/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

export function getEventosAll(token) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

export function deleteEventoApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Actualiza los datos de la usuario
export function updateEvento(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/abono/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.addAbono) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Agregar adicional
export function addAdicional(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/adicional/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.addAdicional) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Agregar adicional
export function actualizaEvento(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Actualizar datos adiconal
export function actualizarAdicional(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/adicionales/actualizar/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Elimina adicionales
export function eliminarAdicional(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/adicionales/eliminar/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Elimina adicionales
export function updateDateEvent(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/event/adicionales/date/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.evento) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}