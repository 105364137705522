import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import {createPackageApi} from '../../api/paquetes';
import {toast} from 'react-toastify';
import './AddPaqueteForm.scss';

export default function AddPaqueteForm({token, idLugar, setReload, setShow}) {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name:"", 
    grande:"", 
    basico:"", 
    descuento:"",   
    descripcion:"",
    grande:"",
    basico:""
});
 const [inputSelect, setInputSelect] = useState("0");
 const [inputColorText, setinputColorText] = useState("0");
useEffect(() => { 
  setInputSelect({
     color:inputSelect,
  })
 }, [])

 useEffect(() => { 
  setinputColorText({
    colorTexto:inputColorText,  
  })
 }, [])

const changeForm = e =>{
  setInputs({...inputs,
[e.target.name] : e.target.value})
}

const register = async e =>{
  const dataNew = {
    name:inputs.name,
    descuento:inputs.descuento,
    color:inputSelect.color,
    colorTexto:inputColorText.colorTexto,
    grande:inputs.grande,
    basico:inputs.basico,
    descripcion:inputs.descripcion,
    tipo:'paquete',
    duracion:inputs.duracion
  }
  e.preventDefault();  

  /* console.log(dataNew) */

    setLoading(true)
     const res = await createPackageApi(dataNew, token, idLugar);
     if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setReload(true);   
        setShow(false);
   }
   setLoading(false);
  
  
}

  return (
    <Form onSubmit={register} onChange={changeForm}>
    <Row>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Nombre del paquete"
    name="name"
    value={inputs.name}
    onChange={e => setInputs({...inputs, name: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="precio 50 a 100 personas"
    name="basico"
    value={inputs.basico}
    onChange={e => setInputs({...inputs, basico: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="precio 101 a 150 personas"
    name="grande"
    value={inputs.grande}
    onChange={e => setInputs({...inputs, grande: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Tiempo de duración"
    name="duracion"
    value={inputs.duracion}
    onChange={e => setInputs({...inputs, duracion: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="Descuento(opcional)"
    name="descuento"
    value={inputs.descuento}
    onChange={e => setInputs({...inputs, descuento: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
     {/*    <Form.Control
    type="color"
    id="exampleColorInput"
    defaultValue="#563d7c"
    title="Selecciona un color de fondo"
    onChange={e => setInputSelect({...inputSelect, color: e.target.value})}
    value={inputSelect.color}
  /> */}
    <Form.Select
    placeholder="Selecciona un color de fondo"
    onChange={e => setInputSelect({...inputSelect, color: e.target.value})}
    value={inputSelect.color}
    >
      <option value="#ffffff">Color de fondo</option>
      <option value="#ff6900" id='naranja'>naranja </option>
      <option value="#fcb900" id='amarillo'>amarillo </option> 
      <option value="#00d084" id='verde1'>verde1 </option> 
      <option value="#69C528" id='verde2'>verde2 </option> 
      <option value="#8ed1fc" id='azul1'>azul1 </option> 
      <option value="#0693e3" id='azul2'>azul2 </option> 
      <option value="#EB144C" id='rojo'>rojo </option> 
      <option value="#F78DA7" id='rosa'>rosa </option> 
      <option value="#6c0e8a" id='morado'>morado </option> 
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
        {/* <Form.Control
    type="color"
    id="exampleColorInput"
    defaultValue="#563d7c"
    title="Selecciona un color de texto"
    onChange={e => setinputColorText({...inputColorText, colorTexto: e.target.value})}
    value={inputColorText.colorTexto}
  /> */}
        <Form.Select
    placeholder="Selecciona un color de texto"
    onChange={e => setinputColorText({...inputColorText, colorTexto: e.target.value})}
    value={inputColorText.colorTexto}
    >
      <option value="#6c0e8a">Color de texto</option>
      <option value="#ffffff" id='blanco'>blanco</option>
      <option value="#000000" id='negro'>negro</option> 
      <option value="#f0f2f5" id='gris'>gris</option> 
      <option value="#a2a2a2" id='gris2'>gris2</option> 
      <option value="#6c0e8a" id='morado'>morado </option>
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control as="textarea" 
              rows={4}
              name="descripcion"
              placeholder="Escribe una descripción del paquete."
              value={inputs.descripcion}
              onChange={ e => setInputs({...inputs, descripcion: e.target.value})}
              />
              </Col>     
    </Row>
    <Button variant="primary" type="submit" disabled={loading}>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Agregar Descripción
    </Button>
</Form>
  );
}

