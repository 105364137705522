import React from 'react';
import './Navbar.scss';
import Logo from '../../../assets/png/doggyWhite.png';
import { Container } from 'react-bootstrap';

const Navbar = () => {
  return (
    <div className='nav_doggy'>
      <Container className='container_nav'>
        {/* Logo centrado arriba */}
        <div className="logo_container">
          <img src={Logo} alt="Logo Doggy by Inflapark" className='logoDoggy' />
        </div>
        {/* Opciones de navegación centradas */}
       {/*  <nav className="nav_options">
          <a href="#inicio">Inicio</a>
          <a href="#atracciones">Nosotros</a>
          <a href="#eventos">Eventos</a>
          <a href="#precios">Precios</a>
          <a href="#contacto">Contacto</a>
        </nav> */}
      </Container>
    </div>
  );
};

export default Navbar;