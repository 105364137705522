import React, {useState, useEffect} from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import {ReactComponent as Add} from "../../../assets/svg/plus-square-regular.svg";
import useAuth from '../../../hooks/useAuth';
import {deleteLugarApi} from '../../../api/lugares';
import Modal from '../../../Components/ModalNormal';
import {ReactComponent as Edit} from "../../../assets/svg/admin/edit-solid.svg";
import {ReactComponent as Delete} from "../../../assets/svg/admin/trash.svg";
import {ReactComponent as Paquete} from "../../../assets/svg/admin/box-open-solid.svg";
import {ReactComponent as Galeria} from "../../../assets/svg/admin/images-regular.svg";
import EditLugarForm from '../../../Components/EditLugarForm';
import AddLugarForm from '../../../Components/AddLugarForm';
import DeleteUserData from '../../../Components/DeleteUser';
import {getLugaresDataApi} from '../../../api/lugares';
import './Lugares.scss';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function Lugares() {
    const [lugar, setLugar] = useState(undefined); 
    const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const {auth} = useAuth();

    

    useEffect(() => {
        (async () =>{
          const response = await getLugaresDataApi();
          setLugar(response.lugar);
          setReload(false)
        })()
      }, [auth, reload]);
  return (
      <div className="container_places_admin">
      <div  className='container_admin_footer_nav'>
     <Container >
<h6>Lugares</h6>
     <AddLugar
     setShow={setShow} 
     setModalTitle={setModalTitle} 
     setModalContent={setModalContent} 
     setReload={setReload}
     token={auth?.token}
     auth={auth?.id}
     />
     </Container>
   </div>
   <Container fluid className="users_section_data">
    <Table responsive>
    <thead>
      <tr>
        <th>Sucursal</th>
        <th>E-mail</th>
        <th>Teléfono</th>
        <th>Horario</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            lugar?.map((item =>{
              return(
              <tr key={item.uid}>
              <td className='options_user_name'>{item.sucursal ?? ''}</td>
              <td><p>{item.email ?? 'No se agrego un e-mail'}</p></td>
              <td>{item.phone ?? ''}</td>
              <td>{item.horario ?? ''}</td>
              <td className='options_user_admin'>
                <div className="edit">
                  <EditUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  auth={auth.id}
                  />
                </div>
                <div className="galery">
                    <GaleriaLugar
                    userdata={item}
                    />
                </div>
                <div className="packages">
                    <PaquetesLugar
                    userdata={item}
                    />
                </div>
                <div className="delete">
                <DeleteUser 
                  userdata={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={deleteLugarApi}
                  />
                </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
   </Container>   
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    />
      </div>
      
  );
}


const EditUser = ({userdata, setShow, setModalTitle, setModalContent, setReload, token, auth}) => {
  
    const editUser = userdata =>{
      setShow(true);
      setModalTitle(`Editanto a ${userdata.sucursal}`)
      setModalContent(<EditLugarForm userdata={userdata} setReload={setReload} setShow={setShow} token={token} auth={auth}/>)
    }
    return (
        <>
      <Edit onClick={()=>editUser(userdata)}/>
      <p>Editar</p>  
        </>
      
    );
  }
  
  const DeleteUser = ({userdata, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction}) => {
    
    const editUser = userdata =>{
      setShow(true);
      setModalTitle("")
      setModalContent(
      <DeleteUserData 
      id={userdata?.uid} 
      setReload={setReload} 
      setShow={setShow} 
      token={token} 
      titulo={`${userdata.sucursal}?`}
      deleteFunction={deleteFunction}   
      />)
    }
    return (
        <>
      <Delete onClick={()=>editUser(userdata)}/>
      <p>Eliminar</p>
      </>
    );
  }

  const GaleriaLugar = ({userdata}) =>{
      return(
          <Link to={`/admin/gallery/${userdata?.uid}`}>
          <Galeria/>
          <p>Galeria</p>
          </Link>
          
      )
  }

  const PaquetesLugar = ({userdata}) =>{
    return(
        <Link to={`/admin/package/${userdata?.uid}`}>
        <Paquete/>
        <p>Paquetes</p>
        </Link>
        
    )
}

const AddLugar = ({ setShow, setModalTitle, setModalContent, setReload, token, auth}) =>{
    const editUser = () =>{
        setShow(true);
        setModalTitle('Agregar un nuevo lugar')
        setModalContent(<AddLugarForm  setReload={setReload} setShow={setShow} token={token} auth={auth}/>)
      }
    return(
        <>
        <Button variant="light" onClick={()=>editUser()}><Add/> Agregar</Button>
        </>
        
    )
}