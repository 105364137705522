import React, {useState} from 'react';
import './SideBar.scss';
import {NavLink} from 'react-router-dom';
import {ReactComponent as Grafica} from "../../assets/svg/sidebar/chart-bar.svg";
import {ReactComponent as Usuarios} from "../../assets/svg/sidebar/users-solid.svg";
import {ReactComponent as Lugares} from "../../assets/svg/sidebar/warehouse-solid.svg";
import {ReactComponent as Slide} from "../../assets/svg/sidebar/desktop-solid.svg";
import {ReactComponent as Social} from "../../assets/svg/sidebar/social.svg";
import {ReactComponent as Config} from "../../assets/svg/sidebar/socks-solid.svg";
import {ReactComponent as User} from "../../assets/svg/user.svg";
import {ReactComponent as AdminIcon} from "../../assets/svg/sidebar/user-cog.svg";
import {ReactComponent as Salir} from "../../assets/svg/sidebar/salir.svg";
import useAuth from '../../hooks/useAuth';


const Sidebar = (props) => {
    const {user, button} = props;
    const {logout} = useAuth();

    const cerrarSesion = () =>{
        logout();
        
      } 
    return (
        <div className={button === false ? 'sidebar' : 'sidebar_reducer'}> 
            <div className="sidebar__menu active">
           <User/>
           <p>{user?.name}</p> 
            </div>
           <NavLink to={"/admin"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Grafica/>
           <p>Principal</p>
           </NavLink>
           {/* <Link to={"/admin/orders"} className="sidebar__menu">
           <AddPedido/>
           <p>Pedidos</p>
           </Link> */}
           <NavLink to={"/admin/admins"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <AdminIcon/>
           <p>Admins.</p>
           </NavLink>
           <NavLink to={"/admin/users"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Usuarios/>
           <p>Usuarios</p>
           </NavLink>
           {
               user?.role === 'super_admin' ?
            <>
              <NavLink to={"/admin/places"}  className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Lugares/>
           <p>Lugares</p>
           </NavLink>
           <NavLink to={"/admin/slides"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Slide/>
           <p>Slide</p>
           </NavLink>
           <NavLink to={"/admin/footer"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Social/>
           <p>Footer</p>
           </NavLink>
           <NavLink to={"/admin/products"} className="sidebar__menu" activeClassName='sidebar__active' exact>
           <Config/>
           <p>Productos</p>
           </NavLink> 
           </>
           : ''
           }
           
           <div className="sidebar__menu" onClick={cerrarSesion}>
           <Salir/>
           <p>Salir</p>
           </div>
        </div>
    );
}

export default Sidebar;
