//Obtener datos de los lugares
export function getLugaresDataApi() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

//Actualiza los datos de la usuario
export function updateLocatesDataApi(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.lugar) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}

//Obtener datos del usuario
export function getLugarDataApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

//Crea un usuario
export function signUpLugarApi(data, token) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.lugar) {
                return { ok: true, message: 'Se agrego un nuevo lugar' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

export function deleteLugarApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.lugar) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

//Obtener datos del usuario
export function getLugarDataName(lugarname) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/locates/name/${lugarname}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}