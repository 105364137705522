import React,{useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {getLugaresDataApi} from '../../../api/lugares';
import LugaresData from '../../LugaresData';
import './Sucursales.scss';


const Sucursales = () => {
  const [lugar, setLugar] = useState([]);
useEffect(() => {
  (async () => {
    const response = await getLugaresDataApi();
    setLugar(response.lugar);
  })(); 
}, [setLugar]);

const [location, setLocation] = useState("");
useEffect(() => {
  if (Array.isArray(lugar) && lugar.length > 0) {
    setLocation({
      sucursal: lugar[0]?.sucursal
    });
  }
}, [lugar]);
    return (
        <div className='container_sucursales' id='Sucursales'>
            <Container>
              {
               (lugar.length <= 0) ?
                <div className="texto">
                  <h1>próximamente...</h1>
                </div>
               : <Row>
               <Col md={6} sm={12} xl={6}>
                   <div className="box">
                    <h2>Selecciona tu inflapark</h2>
                    <Form.Select 
                         aria-label="Default select example"
                         onChange={e => setLocation({...location, sucursal: e.target.value})}
                         value={location.sucursal}
                        >
                          <option value="0">Selecciona un salón</option>
                          {lugar.map(item => (
                            <option key={item._id} value={item.sucursal}>{item.sucursal}</option>
                          ))}
                        </Form.Select>
  
                     <Button>
                     Seleccionar 
                     </Button>
                   </div>
               </Col> 
               <Col md={6} sm={12} xl={6} className='datos_salones'>
               {
                 (location?.sucursal === "0" || location?.sucursal === undefined) ? 
                 "" :
            lugar?.filter(item =>{
              if(item.sucursal === location?.sucursal  ){
                
                return item;
              
              }
              
            }).map((item =>{
              return(
                <LugaresData 
                key={item._id}
                sucursal={item.sucursal}
                direccion={item.direccion}
                horario={item.horario}
                enlace={item.lugarname}
                telefono={item.phone}
                />
            )}))
          }
                
               </Col>      
                </Row>
              }
                
               
            </Container>
            <div className="color_overlay"></div>
        </div>
    );
}

export default Sucursales;
