import React from 'react'
import './Home.scss';
import BannerDoggy from '../BannerDoggy';
import NosotrosDoggy from '../NosotrosDoggy/NosotrosDoggy';
import {Helmet} from 'react-helmet';
import DoggyDes from '../DoggyDes/DoggyDes';
import ContactoDoggy from '../ContactoDoggy/ContactoDoggy';
import CarrouselInfo from '../CarrouselInfo/CarrouselInfo';
const Home = () => {
  return (
    <>
    <Helmet>
    <meta
        name="keywords"
        content="parque de inflables, doggy by inflapark, inflables gigantes, diversión para niños, eventos infantiles, inflables con forma de perrito"
        data-react-helmet="true"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        data-react-helmet="true"
      />
      <meta
        name="twitter:title"
        content="Doggy by Inflapark | Parque de inflables | Diversión en forma de perrito"
        data-react-helmet="true"
      />
      <meta
        name="twitter:description"
        content="Doggy by Inflapark es el nuevo parque de inflables donde la diversión toma forma de perrito. Ven y disfruta de inflables gigantes, actividades emocionantes y diversión para toda la familia."
        data-react-helmet="true"
      />
      <meta
        name="twitter:image"
        content="https://res.cloudinary.com/dhecyqald/image/upload/v1685146144/utils/logo_doggy_black.jpg"
        data-react-helmet="true"
      />
    </Helmet>
    <div className='principalDoggy'>
      <BannerDoggy/>
      <NosotrosDoggy/>
      <DoggyDes/>
      <ContactoDoggy/>
      <CarrouselInfo/>
    </div>
    </>
  )
}

export default Home