import { toast } from "react-toastify";
import { size, includes, remove } from 'lodash'

export function getProductsCart() {
    const cart = localStorage.getItem("cart");

    if (!cart) {
        return null;
    } else {
        const products = cart.split(",");
        return products;
    }
}


export function addProductCart(product) {
    /* const ProductData = JSON.stringify(product)
    console.log(ProductData) */
    const cart = getProductsCart();

    if (!cart) {
        localStorage.setItem("cart", product);
        toast.success("Producto añadido al carrito");
    } else {
        const productFound = includes(cart, product);
        if (productFound) {
            toast.warning("Este producto ya esta en el carrito");
        } else {
            cart.push(product);
            localStorage.setItem("cart", cart);
            toast.success("Producto añadido correctamente");
        }
    }
}

export function countProductsCart() {
    const cart = getProductsCart();

    if (!cart) {
        return 0;
    } else {
        return size(cart);
    }
}