import React from "react";
import "./ContactoDoggy.scss";
import { Col, Container, Row } from "react-bootstrap";

function ContactoDoggy() {
  return (
    <div className="contactoDoggy">
      <Container>
        <h3> ¡Aparta tu lugar en la diversión! </h3>
        <Row>
          <Col md={12} sm={12} xs={12} xl={5}>
            <p className="desc">
              No te quedes fuera de la experiencia más perrona. ¡Reserva tus
              boletos ahora y prepárate para saltar, reír y disfrutar en el
              primer parque de inflables con temática de perritos! 🐶🎈
            </p>
            <br />
            <p>🎟️ Compra tu acceso con anticipación y evita filas.</p>
            <br />
            <p>
              ➡️ ¡Haz tu reserva ahora y ven a vivir la diversión al máximo!
              🚀🐾
            </p>
          </Col>
          <Col md={12} sm={12} xs={12} xl={7}>
           <h3>Próximamente...</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactoDoggy;
