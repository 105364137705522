import { createContext } from 'react';

const AuthContext = createContext({
    auth: undefined,
    login: () => null,
    logout: () => null,
    setReloadUser: () => null,
    user: undefined
});

export default AuthContext;