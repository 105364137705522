import React,{useState} from 'react';
import './ResetPassword.scss';
import {Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import {resetPasswordLocate} from '../../api/user'
import {useFormik} from 'formik';
import {useParams} from 'react-router-dom'; 
import * as Yup from 'yup';
import {toast} from 'react-toastify';

export default function ResetPassword() {
  const {token} = useParams();

  
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
         initialValues: initialValues(),
         validationSchema: Yup.object().shape(validationSchema()),
        onSubmit:async (formData)=> {
          setLoading(true)
         const res = await resetPasswordLocate(token, formData.password);
   
         if(res.ok ===false){
         toast.error(res.message)
      }else{
         toast.success(res.message);
      }
      setLoading(false)
      resetInputPassword();
      resetInputPassword2();
        }
       })
   
       const resetInputPassword = () =>{
        
            formik.values.password=""
    
    }
    const resetInputPassword2 = () =>{
        
      formik.values.password=""

}

  return (
    <div className='container_resetPasword'>
        <Container>
            <Row>
            <Col md={4} sm={0} xl={4} xs={0}></Col>
            <Col md={4} sm={0} xl={4} xs={12} className='container_form'>
            <h1>Escribe un E-mail</h1>
<Form onSubmit={formik.handleSubmit}>
        <Row>
        <Col md={12} sm={12} xl={12}>
        <Form.Group className="mb-3" controlId="formBasicPassword" >
        <Form.Control 
        type="password" 
        placeholder="Password" 
        name="password" 
        onChange={formik.handleChange}
        isInvalid={formik.errors.password && true}
        isValid={!formik.errors.password }
        />
         <Form.Control.Feedback type="invalid">
         {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Form.Group className="mb-3" controlId="formBasicPassword" >
        <Form.Control 
        type="password" 
        placeholder="Repetir password" 
        name="repeatPassword" 
        onChange={formik.handleChange}
        isInvalid={formik.errors.repeatPassword && true}
        isValid={!formik.errors.repeatPassword }
        />
        <Form.Control.Feedback type="invalid">
         {formik.errors.repeatPassword}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Enviar
      </Button>    
        </Col>
      
      </Row>   
  </Form> 
            </Col>
            <Col md={4} sm={0} xl={4} xs={0}></Col>    
            </Row>
        
    </Container>
    </div>
  )
}

function initialValues(){
    return {
      password:"",
      repeatPassword:"",
    }
  }
  function validationSchema(){
    return{
      password: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('repeatPassword')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
      repeatPassword: Yup.string().required('La contraseña es obligatoria',true).oneOf([Yup.ref('password')], 'Las contraseñas no son iguales').min(6, 'La contraseña debe contener un minimo de 6 caracteres'),
    };
  }