import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Logo from '../../assets/png/logo.png';
import './Contrato.scss';
import moment from 'moment';
import 'moment/locale/es-mx'
 

export default function Contrato({dataNew, adminData, user}) {
  const date1 = "YYYY-MM-DD";
 /*  console.log(dataNew) */
  return (
    <Container fluid className='contrato_container'>
    <Row> 
      <Col md={3} sm={3} xl={3}>
        <img src={Logo} alt="" />
      </Col>
      <Col md={9} sm={9} xl={9}> 
        <Row>
        <Col md={12} sm={12} xl={12}>
        <h6>Nombre del evento: <span>{dataNew.name ? dataNew.name : 'Agrega el nombre del evento'}</span></h6>
        </Col> 
        <Col md={6} sm={6} xl={6}>
        <h6>Paquete: <span>{dataNew.paquete ? dataNew.paquete : 'No seleccionaste ningún paquete'}</span></h6>
        </Col> 
        <Col md={6} sm={6} xl={6}>
        <h6>Turno: <span>{dataNew.turno ? dataNew.turno : 'No seleccionaste ningún turno'}</span></h6>
        </Col>  
        <Col md={6} sm={6} xl={6}>
        <h6>Personas: <span>{dataNew.personas ? dataNew.personas : 'No agregaste numero de personas'}</span></h6>
        </Col> 
        <Col md={6} sm={6} xl={6}>
        <h6>Fecha del evento: <span>{ dataNew?.fecha ?
        moment(dataNew?.fecha).format(date1)
        /*  Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(Date.parse(dataNew?.fecha))   */ 
         : 'No agregaste fecha del evento'}</span></h6>
        </Col>
        <Col md={6} sm={6} xl={6} xs={6}>
              <h6>Inicio del evento: <span>{dataNew?.entrada ?  `${dataNew?.entrada} Hrs.` : 'No agregaste Hora de entrada'}</span></h6>
              </Col>
              <Col md={6} sm={6} xl={6} xs={6}>
              <h6>Hora de termino: <span>{dataNew?.salida ? `${dataNew?.salida} Hrs.` : 'No agregaste Hora de salida'}</span></h6>
              </Col> 
        </Row>
      </Col>
    </Row>
    <Row>
    <Col md={9} sm={9} xl={9} className='admindatos'>
        <h5>Vendedor: <span>{adminData?.name ? adminData?.name : ''}  {adminData?.lastname ? adminData?.lastname : ''}</span></h5>
        </Col> 
        <Col md={3} sm={3} xl={3} className="total">
        <h5>Total: <span>${dataNew?.precioTotal ? Intl.NumberFormat('es-MX').format(dataNew?.precioTotal) : 0}</span></h5>
        </Col>   
    </Row>
    <hr />
    <Row className='Pagos_container'>
     <h3>Pagos</h3>
      <PagosData abonos={dataNew?.abonos} date1={date1}/>
    </Row>
    <Row className='adicionales_container'>
     <h3>Extras</h3>
      <ExtrasData adicionales={dataNew?.adicionales}/>
    </Row>
    {
     (dataNew.guisado !== '' || dataNew.guisado1 !== '' || dataNew.guisado2 !== '' || dataNew.guisado3 !== '' || dataNew.guisado4 !== '' || dataNew.infantil !=='') ?
    <MenuData 
    guisado={dataNew.guisado}
    guisado1={dataNew.guisado1}
    guisado2={dataNew.guisado2}
    guisado3={dataNew.guisado3}
    guisado4={dataNew.guisado4}
    infantil={dataNew.infantil} />
     
    :
    ''
    }
    <Row>
    {
      dataNew.pastel ?
      <Col md={12} sm={12} xl={12}>
        <p>Pastel: <span>{dataNew.pastel}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.piñata ?
      <Col md={12} sm={12} xl={12}>
        <p>Piñata: <span>{dataNew.piñata}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.refrescos ?
      <Col md={12} sm={12} xl={12}>
        <p>Refrescos: <span>{dataNew.refrescos}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.agua ?
      <Col md={12} sm={12} xl={12}>
        <p>Agua: <span>{dataNew.agua}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.dulceros ?
      <Col md={12} sm={12} xl={12}>
        <p>Dulceros: <span>{dataNew.dulceros}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.show ?
      <Col md={12} sm={12} xl={12}>
        <p>Show: <span>{dataNew.show}</span></p>
      </Col>
      : '' 
    }
    {
      dataNew.comentarios ?
      <>
      <h3>Comentarios</h3>
      <Row>
      <Col md={12} sm={12} xl={12} className='comentarios_data'>
        <p>{dataNew.comentarios}</p>
      </Col>
      </Row>
      
      </>
      : '' 
    }
    </Row>
  {/*   <Row className='row_firma_usuario'>
    <Col md={4} sm={4} xl={4}>
      </Col>  
      <Col md={4} sm={4} xl={4} className='firma_usuario'>
       
       <p>{user?.name ? user?.name : ''} {user?.lastname ? user?.lastname : ''}</p>  
      </Col>  
      <Col md={4} sm={4} xl={4}>
      </Col>  
    </Row> */}
    </Container>
  )
}

const PagosData =({abonos, date1}) =>{
  
return(
  abonos?.map((item =>{
    return(
      <>
      <Col md={3} sm={3} xl={3} className='admindatos'>
      <p>Abono: <span>${item?.abono ? Intl.NumberFormat('es-MX').format(item?.abono)  : 'No se agrego una cantidad'}</span> </p>
      </Col>
      <Col md={3} sm={3} xl={3} className='admindatos'>
      <p>Fecha: <span>{item?.fechadePago ? 
      moment(item?.fechadePago).format(date1)
      /* Intl.DateTimeFormat('es-MX', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item?.fechadePago) */ 
       : 'No se agrego fecha'}</span> </p>
      </Col> 
      <Col md={6} sm={6} xl={6}>
        <h6>Método de pago :<span>{item.metodo ? item.metodo : 'No seleccionaste ningúna forma de pago'}</span></h6>
        </Col> 
      </>
  )}))
)
}

const ExtrasData =({adicionales}) =>{
  
  return(
    adicionales?.map((item =>{
      return(
        <>
        <Col md={9} sm={9} xl={9} className='admindatos'>
        <p>Nombre: <span>{item?.nombre ? item?.nombre   : 'No se agrego un nombre'}</span> </p>
        </Col>
        <Col md={3} sm={3} xl={3} className='admindatos'>
        <p>Cantidad: <span>{item?.piezas ? item?.piezas   : 'No se agrego número'}</span> </p>
        </Col>
        </>
    )}))
  )
  }

  const MenuData =({guisado,guisado1,guisado2,guisado3,guisado4,infantil }) =>{
  
    return(
      <Row>
        {
          guisado !=='' ||guisado1 !== '' || guisado2 !=='' || guisado3 !== '' || guisado4 !== ''?
          <>
          <h3>Menú</h3>
        <Col md={12} sm={12} xl={12} >
       {guisado ? <p> <span>Guisado 1:{guisado}</span> </p>: '' }
        </Col>
        <Col md={12} sm={12} xl={12} >
       {guisado1 ? <p> <span>Guisado 2:{guisado1}</span> </p>: '' }
        </Col>
        <Col md={12} sm={12} xl={12} >
       {guisado2 ? <p> <span>Guisado 3:{guisado2}</span> </p>: '' }
        </Col>
        <Col md={12} sm={12} xl={12} >
       {guisado3 ? <p> <span>Guisado 4:{guisado3}</span> </p>: '' }
        </Col>
        <Col md={12} sm={12} xl={12} >
       {guisado4 ? <p> <span>Guisado 5:{guisado4}</span> </p>: '' }
        </Col>
        </>
        : ''
        }
        {
          infantil ?
          <>
          <h3>Menú infantil</h3>
          <Col md={12} sm={12} xl={12} >
       <p>Menu infantil: <span>{infantil}</span> </p>
        </Col>
          </>
          : ''
        }
        
      </Row>
    )
    }  

    