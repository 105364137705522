import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row} from 'react-bootstrap';
import './EditUserForm.scss';
import {updateUsuarioApi} from '../../api/user';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({userdata ,setReload,setShow, token, auth, lugares, admin}) {
  
 
  const [updateUser, setUpdateUser] = useState({});
  useEffect(() => {
    setUpdateUser({
     name:userdata.name, 
     lastname:userdata.lastname,
     phone: userdata.phone,
     active: userdata.active,
     email:userdata.email,
     role:userdata.role,
     password:userdata.password,
     lugar:userdata.lugar
    })
   }, [userdata])



     /* Se actualizan los datos del puesto */
  const update = e =>{
    e.preventDefault();
    let updateDataUser = updateUser;
/* 
    if(!updateDataUser.phone.length !==10){
      toast.error("Número de teléfono debe de contener 10 dígitos.")
      return;
    } */

    if(!updateDataUser.name || !updateDataUser.email){
      toast.error("Nombre y e-mail son obligatorios.")
      return;
    }
    
  else{
    updateUsuarioApi(token, updateDataUser, userdata.uid).then(res =>{
      
      if(res.ok === true){
        toast.success(res.message);
        setShow(false);
        setReload(true);        
   }else{
      toast.error(res.message)
   }
      })
      
    }
    }
  return (
    <Form onSubmit={update}>
      <Row>
      <Col md={6} sm={0} xl={6}>
      {
        admin?.role == "super_admin"  && userdata?.role !== 'user' ?
                   <div className="box">
                      <Form.Select 
                      aria-label="Default select example"
                      onChange={e => setUpdateUser({...updateUser, lugar: e.target.value})}
                      value={updateUser.lugar}
                      >
                      <option value="">Selecciona un lugar</option>
                {
                  lugares?.map((item) =>{
                    return(
                    <option key={item.uid} value={item.uid}>{item.sucursal}</option>
                    )
                  })
                }
                      </Form.Select>    
                   </div>
                :
        ""
      }
        </Col>
      <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail" id='label_switch'>
      <Form.Label>{updateUser.active === false ? 'Usuario inactivo' : 'Usuario activo'}</Form.Label>
        <Form.Check 
      type="switch" 
      checked={updateUser.active}
      onChange={ e => setUpdateUser({...updateUser, active: updateUser.active === !e.target.value })}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Nombre de usuario"
      value={updateUser.name}
      onChange={ e => setUpdateUser({...updateUser, name: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Apellido(s) del usuario"
      value={updateUser.lastname}
      onChange={ e => setUpdateUser({...updateUser, lastname: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="phone" 
      placeholder="Teléfono (10) digitos"
      value={updateUser.phone}
      onChange={ e => setUpdateUser({...updateUser, phone: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="emali" 
      placeholder="E-mail"
      value={updateUser.email}
      onChange={ e => setUpdateUser({...updateUser, email: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="password" 
      placeholder="Password"
      value={updateUser.password}
      onChange={ e => setUpdateUser({...updateUser, password: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
    <Form.Select
    placeholder="Selecciona un rol"
    onChange={e => setUpdateUser({...updateUser, role: e.target.value})}
    value={updateUser.role}
    >
      <option value="user">usuario</option>
      <option value="admin">administrador</option>
      {
        admin?.role === "super_admin" ?
        <option value="super_admin">usuario maestro</option> :
        ""
      }  
    </Form.Select>
   </Form.Group>
        </Col>
      </Row>
    <Button variant="primary" type="submit">
      Actualizar
    </Button>
  </Form>
  );
}

export default EditUserForm;
