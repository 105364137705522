import React,{useState, useEffect} from 'react';
import './LugarData.scss';
import {getLugarDataName} from '../../api/lugares';
import {useParams} from 'react-router-dom'
import Paquetes from '../../Components/PerfilLugar/Paquetes';
import Cotizador from '../../Components/PerfilLugar/Cotizador';
import Galeria from '../../Components/PerfilLugar/Galeria';
import Map from '../../Components/PerfilLugar/Mapa';
import Pulse from '../../Components/ButtonWhatsApp';
import Banner from '../../Components/PerfilLugar/BannerPerfil';
import Error404 from '../../pages/Error404';
import {Helmet} from 'react-helmet';
import Logo from '../../assets/png/logo.png';
 


export default function LugarData() {
  const [lugar, setLugar] = useState(undefined); 
  const params = useParams();

 useEffect(() => {
        (async () =>{
          const response = await getLugarDataName(params?.lugarname);
          setLugar(response.lugarData[0]);
        })()
      }, []);
  return(  
    <>
    {
      lugar ?
      <>
      <Helmet>
            <title>Inflapark {lugar?.sucursal}</title>
            <meta name='description' content={`nos encontramos en ${lugar?.direccion}.`} data-react-helmet="true"/>
            <meta name='og:description' content={`nos encontramos en ${lugar?.direccion}.`} data-react-helmet="true"/>
            <meta name='og:title' content={`Inflapark ${lugar?.sucursal}`} data-react-helmet="true"/>
            <meta property="og:url" content={`https://inflapark.com.mx/${params?.lugarname}`} data-react-helmet="true"/>
            <meta property="og:site_name" content={`Parque inflable en ${params?.lugarname}`}  data-react-helmet="true"/>
            <meta property="article:publisher" content="https://www.facebook.com/inflapark" data-react-helmet="true"/>
            <meta name="og:image" content="https://res.cloudinary.com/dhecyqald/image/upload/v1685146144/utils/logo_black_cbbmc0.jpg" data-react-helmet="true"/>
        </Helmet>
      <div className='container_profile_lugar'>
      <Banner/>
      <Cotizador idLugar={lugar?.uid}/>
      <Paquetes 
      idLugar={lugar?.uid}
      name={lugar?.sucursal}
      phone={lugar?.phone}
      lugarname={lugar?.lugarname}
      />
      <Galeria idLugar={lugar?.uid}/>
      <Map direccion={lugar?.direccion} mapaURL={lugar?.mapaURL}/>
      {
        lugar?.whatsApp ? <Pulse numero={lugar?.whatsApp}/> : ''
      }
      
  </div> 
  </>
  : <Error404/>
    }
    </>
  
  );
}
