import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import './EditUserForm.scss';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {signUpLugarApi} from '../../api/lugares';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({setReload,setShow, token, auth}) {
  const [loading, setLoading] = useState(false);
 const formik = useFormik({
      initialValues: initialValues(),
      validationSchema: Yup.object().shape(validationSchema()),
     onSubmit:async (formData)=> {
       setLoading(true)
      const res = await signUpLugarApi(formData, token);

      if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setShow(false);
        setReload(true); 
   }
   setLoading(false)
     }
    })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Sucursal"
      name='sucursal'
      value={formik.values.sucursal}
        onChange={formik.handleChange}
        isInvalid={formik.errors.sucursal && true}
        isValid={!formik.errors.sucursal}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.sucursal}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="url lugar"
      value={formik.values.lugarname}
      name='lugarname'
      onChange={formik.handleChange}
      isInvalid={formik.errors.lugarname && true}
      isValid={!formik.errors.lugarname}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.lugarname}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="phone" 
      placeholder="Teléfono (10) digitos"
      name='phone'
      value={formik.values.phone}
      onChange={formik.handleChange}
      isInvalid={formik.errors.phone}
      isValid={!formik.errors.phone}
      />
      <Form.Control.Feedback type="invalid"> 
         {formik.errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="emali" 
      placeholder="E-mail"
      name='email'
      value={formik.values.email}
      onChange={formik.handleChange}
      isInvalid={formik.errors.email}
      isValid={!formik.errors.email}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.email}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={6} sm={6} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="number" 
      placeholder="WhatsApp"
      name="whatsApp"
      value={formik.values.whatsApp}
        onChange={formik.handleChange}
        isInvalid={formik.errors.whatsApp}
        isValid={!formik.errors.whatsApp}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.whatsApp}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={6} sm={6} xl={6}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Horario"
      name="horario"
      value={formik.values.horario}
        onChange={formik.handleChange}
        isInvalid={formik.errors.horario}
        isValid={!formik.errors.horario}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.horario}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Control 
      type="text" 
      placeholder="Agregue una URL de mapa de google"
      name='mapaURL'
      value={formik.values.mapaURL}
        onChange={formik.handleChange}
        isInvalid={formik.errors.mapaURL}
        isValid={!formik.errors.mapaURL}
      />
      <Form.Control.Feedback type="invalid">
         {formik.errors.mapaURL}
        </Form.Control.Feedback>
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Form.Group className="mb-3" controlId="ControlTextarea1">
        <Form.Control 
         as="textarea"
         rows={3}
         placeholder="Dirección"
         name='direccion'
         value={formik.values.direccion}
        onChange={formik.handleChange}
        isInvalid={formik.errors.direccion}
        isValid={!formik.errors.direccion}
         />
         <Form.Control.Feedback type="invalid">
         {formik.errors.direccion}
        </Form.Control.Feedback>
        </Form.Group>
        </Col>
      </Row>
      <Button variant="primary" type="submit" disabled={loading}>
        {
          loading === false ? '' :
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        }
        Agregar Lugar
      </Button>
  </Form>
  );
}

function initialValues(){
  return {
    sucursal:"",
    lugarname:"",
    horario:"",
    direccion:"",
    email:"",
    mapaURL:"",
    phone:"",
    whatsApp:""
  }
}
function validationSchema(){
  return{
    mapaURL: Yup.string(),
    sucursal: Yup.string().required('Ingrese nombre de la sucursal', true),
    horario: Yup.string(),
    lugarname: Yup.string().required('Ingrese nombre del lugar(para la dirección web)', true),
    direccion: Yup.string(),
    email: Yup.string().email("El email no es valido"),
    phone: Yup.string().min(10, 'El numéro debe contener un minimo de 10 caracteres'),
    whatsApp: Yup.string().min(10, 'El numéro debe contener un minimo de 10 caracteres'),
  };
}

export default EditUserForm;
