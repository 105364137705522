import React, {useEffect, useState}  from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import NavBar from '../Components/NavbarAdmin';
import {getLUserDataApi} from '../api/user';
import SideBar from '../Components/SideBar';
import useAuth from '../hooks/useAuth';
import './layoutAdmin.scss';


export default function LayoutAdmin(props) {
  const {routes} = props;
  const [button, setButton] = useState(false);
  const [user, setUser] = useState(undefined);
  const {auth} = useAuth();

  useEffect(() => {
    (async () =>{
      const response = await getLUserDataApi(auth.token, auth.id);
      setUser(response.userData);
    })()
  }, [auth]);
   

  if(!auth && user?.role !=="admin" || !auth && user?.role !=="super_admin" || auth && user?.role ==="user"){
      return(
        <>
        <Route path="/"/>
        <Redirect to ="/" />
        </>
      )
    }
  
    if(auth && user?.role ==="admin" || auth && user?.role ==="super_admin"){
      return (
          <div>
           <NavBar user={user} button={button} setButton={setButton}/>
           <SideBar user={user} button={button} setButton={setButton}/>
           <div className={button ? 'container_admin' : 'container_admin_active'} style={{marginLeft: button ? "70px" : "200px", transition: "all 0.3s ease-in"}}>
            <LoadRoutes routes={routes}/>   
           </div>
          </div>
        )  
    }
  
   return null;
  
  }
  
  
  function LoadRoutes({ routes }) {
    return ( 
    <Switch> {
            routes.map((route, index) => (
                 <Route key = { index }
                 path = { route.path }
                 exact = { route.exact }
                 component = { route.component }
                />
            ))
        } 
        </Switch>
    );
  
  }