import React, {useState} from 'react';
import {eliminarAdicional} from '../../api/eventos'; 
import {ReactComponent as EditarIcon} from "../../assets/svg/admin/edit-solid.svg";
import {ReactComponent as EliminarIcon} from "../../assets/svg/admin/trash.svg";
import DeleteUserData from '../../Components/DeleteAdicional';
import AddAdicionales from '../AddAdicionales';
import Modal from '../../Components/ModalNormal';
import './AdiconalesTable.scss';
import useAuth from '../../hooks/useAuth';
import { Table } from 'react-bootstrap';

export default function AdiconalesTable({adicionales, setReload, reload, uid}) {
    const {auth} = useAuth();
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState(""); 
    const [modalContent, setModalContent] = useState(null);

  return (
      <>
    <Table responsive>
    <thead>
      <tr>
        <th>Adicional</th>
        <th>Piezas</th>
        <th>Precio</th>
        <th>Total</th>
        <th>Opciones</th>
      </tr>
    </thead>
    <tbody>
          {
            adicionales?.map((item =>{
              return(
              <tr key={item._id}>
              <td className='options_user_name'>{item.nombre ?? ''}</td>
              <td><p>{item.piezas ?? 'No se agrego un e-mail'}</p></td>
              <td>${Intl.NumberFormat('es-MX').format(item.precio ) ?? ''}</td>
              <td>${Intl.NumberFormat('es-MX').format(item.total ) ?? ''}</td>
              <td className='options_user_admin'>
                <div className="edit">
                  <EditUser 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  reload={reload}
                  token={auth.token}
                  auth={auth.id}
                  uid={uid}
                  />
                </div>
                <div className="delete">
                <DeleteUser 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={eliminarAdicional}
                  uid={uid}
                  />
                </div>
                 </td>
              </tr>
            )}))
          }
        </tbody>
    </Table>
    <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
    </>
  )

  
}

const EditUser = ({data, setShow, setModalTitle, setModalContent, setReload, token, auth, reload, uid}) => {
    
    const editUser = data =>{
      setShow(true);
      setModalTitle(`${data.nombre}`)
      setModalContent(<AddAdicionales data={data} setReload={setReload} setShow={setShow} token={token} auth={auth} reload={reload} uid={uid}/>)
    }
    return (
        <>
      <EditarIcon onClick={()=>editUser(data)}/>
      <p>Editar</p>  
        </>
      
    );
  }
  
  const DeleteUser = ({data, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction, uid}) => {
     
    
    const editUser = data =>{
      setShow(true);
      setModalTitle("")
      setModalContent(
      <DeleteUserData 
      id={uid} 
      data={data}
      setReload={setReload} 
      setShow={setShow} 
      token={token} 
      titulo={`${data.nombre}?`}
      deleteFunction={deleteFunction}   
      />)
    }
    return (
        <>
      <EliminarIcon onClick={()=>editUser(data)}/>
      <p>Eliminar</p>
      </>
    );
  }