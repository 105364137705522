import React from "react";
import "./NosotrosDoggy.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import Parque from "../../../assets/png/parqueSombra.png";

function NosotrosDoggy() {
  return (

      <div className="nosotros_doggy">
        <Container>
          <h3>Sobre Nosotros </h3>
          <Row>
            <Col md={12} sm={12} xs={12} xl={5}>
              <p>
                Doggy by Inflapark es el nuevo parque de inflables donde la
                diversión cobra vida. Inspirados en los clásicos perritos de
                globo, hemos creado un espacio lleno de estructuras gigantes,
                coloridas y emocionantes para que niños y adultos disfruten al
                máximo.
              </p>
            </Col>
            <Col md={12} sm={12} xs={12} xl={7}>
              <Image src={Parque} className="img_nosotros"/>
            </Col>
          </Row>
        </Container>
      </div>

  );
}

export default NosotrosDoggy;
