import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import {createProductApi} from '../../api/productos';
import {toast} from 'react-toastify';
import './AddPaqueteForm.scss';

export default function AddPaqueteForm({token, setReload, setShow}) {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name:"", 
    precio:"",  
    descripcion:"",   
});
 const [inputSelect, setInputSelect] = useState("0");
 const [inputColorText, setinputColorText] = useState("0");
useEffect(() => { 
  setInputSelect({ 
     color:inputSelect,
  })
 }, [])

 useEffect(() => { 
  setinputColorText({
    colorTexto:inputColorText,  
  })
 }, [])

const changeForm = e =>{
  setInputs({...inputs,
[e.target.name] : e.target.value})
}

const register = async e =>{
  const dataNew = {
    name:inputs.name,
    descripcion:inputs.descripcion,
    precio:inputs.precio,
    tipo:'producto'
  }
  e.preventDefault();  

    setLoading(true)
     const res = await createProductApi(dataNew, token);
     if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setReload(true);   
        setShow(false);
   }
   setLoading(false);
  
  
}

  return (
    <Form onSubmit={register} onChange={changeForm}>
    <Row>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="text" 
    placeholder="Nombre del producto"
    name="name"
    value={inputs.name}
    onChange={e => setInputs({...inputs, name: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    name="precio"
    placeholder="Precio del producto o servicio"
    value={inputs.precio}
    onChange={e => setInputs({...inputs, precio: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control as="textarea" 
              rows={4}
              name="descripcion"
              placeholder="Escribe una descripción del producto."
              value={inputs.descripcion}
              onChange={ e => setInputs({...inputs, descripcion: e.target.value})}
              />
              </Col>
    </Row>
    <Button variant="primary" type="submit" disabled={loading}>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Agregar Producto
    </Button>
</Form>
  );
}

