import React from "react";
import "./DoggyDes.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import Mascota from "../../../assets/png/doggySombras.png";

function DoggyDes() {
  return (
    <div className="doggyDes">
      <Container>
        <h3>
          Doggy #ESTABIENPERRO <br />
          ¡Ven a saltar y ladrar de felicidad!
        </h3>
        <p className="subtitulo">
          ¡Salta, corre y diviértete en el parque más perrón!
        </p>
        <Row>
          <Col md={12} sm={12} xs={12} xl={5}>
            <p className="desc">
              Bienvenidos a Doggy, el primer parque del mundo con más de 2000
              metros cuadrados de inflables en forma de perrito. Un lugar donde
              la diversión no tiene límites, ¡porque aquí podrás saltar,
              deslizarte y correr entre los inflables más cool! Ven y vive una
              experiencia única llena de risas, adrenalina y pura diversión. ¡El
              parque perfecto para disfrutar con amigos y familia, y liberar
              toda tu energía!
            </p>
          </Col>
          <Col md={12} sm={12} xs={12} xl={7}>
            <Image src={Mascota} className="img_mascota" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DoggyDes;
