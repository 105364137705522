import React, {useState} from 'react'
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import {useParams} from 'react-router-dom'; 
import {ReactComponent as Add} from "../../assets/svg/plus-square-regular.svg";
import {ReactComponent as Close} from "../../assets/svg/times-solid.svg";
import NoImage from "../../assets/png/gallery.png";
import useAuth from '../../hooks/useAuth';
import Modal from '../../Components/ModalNormal';
import AddImageForm from '../../Components/AddImageForm';
import DeleteUserData from '../../Components/DeleteUser';
import NoItem from '../../Components/NoItem';
import './Galeria.scss';

export default function Galeria({titulo, galeria, setReload, addFunction, deleteFunction, slide}) {
  const [lugarName, setLugarName] = useState(undefined);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const params = useParams();
  const {auth} = useAuth();

  return (
    <div className="container_galleria_seccion">
     <div  className='container_admin_footer_nav'>
     <Container > 
<h5>{titulo}</h5>
     <AddLugar
     setShow={setShow} 
     setModalTitle={setModalTitle} 
     setModalContent={setModalContent} 
     setReload={setReload}
     token={auth?.token}
     auth={params?.id}
     addFunction={addFunction}
     idLugar={lugarName}
     slide={slide}
     />
     </Container>
   </div>
   <Container className='container_body_galeria'>
     <Row>
       
      {
        (galeria?.length === 0 || galeria === undefined)?
            <NoItem
            imagen={NoImage}
            texto={'No tienes imágenes en esta galería'}
            />
            :
            galeria?.map((item =>{
              return(
                <Col md={4} sm={4} xl={4} xs={6} className='col_galeria'>
                <ImageData 
                  data={item} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  token={auth.token}
                  deleteFunction={deleteFunction}
                  setLugarName={setLugarName}
                  />
                </Col>
            )}))
          } 
       
     </Row>
   
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    /> 
    </div>
      
  );
}


const AddLugar = ({ setShow, setModalTitle, setModalContent, setReload, token, auth, addFunction, idLugar, slide}) =>{
  const editUser = () =>{
      setShow(true);
      setModalTitle('Agregar un nuevo lugar')
      setModalContent(<AddImageForm  setReload={setReload} setShow={setShow} token={token} auth={auth} addFunction={addFunction} idLugar={idLugar} slide={slide}/>)
    }
  return(
      <>
      <Button variant="light" onClick={()=>editUser()}><Add/>Agregar</Button>
      </>
      
  )
}

const ImageData = ({data, setShow, setReload, token,  setModalTitle, setModalContent, deleteFunction, setLugarName}) =>{
  setLugarName(data?.lugar)
  const deleteImage = data =>{
    setShow(true);
      setModalTitle("")
      setModalContent(
      <DeleteUserData 
      id={data?._id} 
      setReload={setReload} 
      setShow={setShow} 
      token={token} 
      titulo={`'esta imagen?'`}
      deleteFunction={deleteFunction}   
      />)
    
  }
  return(
     <>
     <div className="delete_image">
                  <Close onClick={()=>deleteImage(data)}/>
     </div>
     <Image fluid src={data?.imagen}  />
     </>
  )
}