import React, {useState} from 'react';
import {Form, Button, Col, Row, Spinner} from 'react-bootstrap';
import {updateEvento} from '../../api/eventos';
import {toast} from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import './AddPaqueteForm.scss';

export default function AddPaqueteForm({token, idEvento, setReload, setShow, data}) {
  const [loading, setLoading] = useState(false);
  const {auth} = useAuth();
 //Se llama data para utilizar el correo del usuario y tomar el nombre del usuario 
const [abonos, setAbonos] = useState([{
  abono:0,
  metodo:'',
  fechadePago: Date.now(),
  recibio:auth?.id
 
}]);


const changeForm = e =>{
  setAbonos({...abonos,
[e.target.name] : e.target.value})
}

const register = async e =>{

  const dataAbono ={
    abono:abonos.abono,
    metodo:abonos.metodo,
    fechadePago: abonos.fechadePago,
    recibio:auth?.id
  }
  console.log(dataAbono.abono)
  if(dataAbono.abono <= 0){
    e.preventDefault(); 
    toast.error('Agrega una cantidad minima de abono.')
    return;
  }

  const dataNew = {
    abonos:dataAbono,
  }
  e.preventDefault();  

    setLoading(true)
     const res = await updateEvento(token, dataNew, idEvento);
     if(res.ok ===false){
      toast.error(res.message)
   }else{
      toast.success(res.message);
        setReload(true);   
        setShow(false);
   } 
   setLoading(false);
  
  
}

  return (
    <Form onSubmit={register} onChange={changeForm}>
    <Row>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Método de pago</Form.Label>
    <Form.Select
    placeholder="Selecciona un número de personas"
    name='metodo'
    value={abonos.metodo}
    onChange={e => setAbonos({...abonos, metodo: e.target.value})}
    >
      <option value="No seleccionada">Selecciona una opción</option>
      <option value="Pago en efectivo">Pago en efectivo</option>
      <option value="Tarjeta de crédito">Tarjeta de crédito</option>
      <option value="Depósito bancario">Depósito bancario</option>
      <option value="Transferencia Electrónica">Transferencia Electrónica</option>
      <option value="¨Paypal">PayPal</option>
    </Form.Select>
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
        <Form.Group className="mb-3">
        <Form.Label>Fecha del abono</Form.Label>
        <Form.Control 
    type="date" 
    placeholder="Seleccione una fecha"
    name="fechadePago"
    value={abonos.fechadePago}
    onChange={e => setAbonos({...abonos, fechadePago: e.target.value})}
    />
   </Form.Group>
        </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Abono a cuenta</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Abono a cuenta"
    name="abono"
    value={abonos.abono}
    onChange={e => setAbonos({...abonos, abono: e.target.value})}
    />
    </Form.Group>
      </Col>
    </Row>
    <Button variant="primary" type="submit" disabled={loading}>
      {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Agregar Descripción
    </Button>
</Form>
  );
}

