import React, {useState, useEffect} from 'react';
import {getGalleryApi} from '../../../api/galeria';
import {ReactComponent as Close} from "../../../assets/svg/times-solid.svg";
import { Container, Row, Col, Button} from 'react-bootstrap';
import './Galeria.scss';
const Galeria = ({idLugar}) => {
    const [galeria, setGaleria] = useState(null);
    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
   /*  console.log(galeria); */
useEffect(() => { 
    (async () =>{
      const response = await getGalleryApi(idLugar);
      setGaleria(response.galeria);
    })()
  }, [idLugar]);
 
  const getImgFnc =(imgSrc) =>{
    setTempImgSrc(imgSrc);
    setModel(true);
  }


    return (
      <>
        {
         (galeria?.length === 0 || galeria === undefined) ?
         
         '' :

         <div className="galeria_container" id='Galería'>
            <div className={model ? "model open" : "model"}>
              <img src={tempImgSrc} alt=""  />  
              <Close onClick={() => setModel(false)}/>
            </div>
            <h1>Galería</h1>
         <Container className='gallery'>
          
            {
       galeria?.map((item) =>{
         
         return(
          <div className='card_image' key={item._id} onClick={() => getImgFnc(item?.imagen)}>
                <div className="content"><p>{item?.title ? item?.title : ''}</p></div> 
                <img src={item?.imagen} alt="" style={{width: '100%'}} />
            </div>
          
       )})
      }
        </Container>   
        </div>  
        
        }
        </>
        
    );
}


export default Galeria;
