import React from "react";
import "./Footer.scss";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ReactComponent as Marcador } from "../../../assets/svg/marcador.svg";
import { ReactComponent as Whats } from "../../../assets/svg/whatsapp.svg";
import { ReactComponent as Reloj } from "../../../assets/svg/reloj-cinco.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/facebookDoggy.svg";
import Logo from "../../../assets/png/doggyLogo.png";

export const Footer = () => {
  return (
    <div className="footer_doggy">
      <Container>
        <Row className="row_footer">
          <Col md={6} sm={12} xs={12} xl={6}>
            <Image src={Logo} className="logoFooter" />
            <div className="svg-text-container">
              <Marcador />
              <span className="text">
                Av Cerro Sombrerete 1199, Misión Bucareli Sur,
                <br /> 76168 Santiago de Querétaro, Qro.
              </span>
            </div>
            <div className="svg-text-container">
              <Whats />
              <span className="text">
                Más información al: <br />{" "}
                <a
                  href="https://wa.me/5533442211?text=Quiero%20más%20información%20de%20Doggy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsapp-link"
                >
                  55 3344 2211
                </a>
              </span>
            </div>
            <div className="svg-text-container">
              <Reloj />
              <span className="text">
                Horarios:
                <br />
                Lunes a Viernes 11:00 a 21:00hrs
                <br /> Sábado y Domingo de 10:00 a 21:00hrs
              </span>
            </div>
          </Col>
          <Col md={6} sm={12} xs={12} xl={6} className="redes">
            <a
              href="https://www.facebook.com/share/1XAAHkk6CH/?mibextid=wwXIfr"
              target="_blank"
              rel="noopener noreferrer"
              className="facebook-link"
            >
              <Facebook />
            </a>
            <br />
            <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.711157599309!2d-100.41807702412713!3d20.64062700099516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35be14a04318b%3A0xe7a8d32f1c350a0d!2sPortal%20Quer%C3%A9taro!5e0!3m2!1ses-419!2smx!4v1738356472176!5m2!1ses-419!2smx"
  style={{ width: "100%", height: "250px", border: 0 }}
  allowFullScreen
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

          </Col>
        </Row>
      </Container>
    </div>
  );
};
