import React,{useState, useEffect} from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap';
import {getProductosApi} from '../../../api/productos';
import {ReactComponent as Delete} from "../../../assets/svg/times-solid.svg";
import {ReactComponent as Add} from "../../../assets/svg/plus-square-regular.svg";
import './AddEventoAdmin.scss';

export default function AddEventoAdmin({setReload, reload, adicionales, cambioData, AddFormProducto, RemoveFormProducto}) {
    const [productos, setProductos] = useState(undefined);

/*     const [number, setNumber] = useState(1);
    
    
    const addProduct = () =>{
      setNumber((number) => number +1);
     adicionales.piezas = number +1;
  }

  const removeProduct = () =>{
    if(number > 1){
      setNumber((number) => number -1);
      adicionales.piezas = number -1;
    }
}
     */
   
    /* console.log(adicionales); */
  useEffect(() => {
    (async () =>{
      const response = await getProductosApi();
      setProductos(response.productos);
      setReload(false)
    })()
  }, [ reload]);
  
  
  

  return (
    <div className='Container_form_evento_admin'>
    <Form>
        {adicionales.map((item, index) =>(
            <div key={index}>
            <Row>
            <div className="funciones_producto">
          <h6>producto adicional</h6>
          <Delete
          onClick={() => RemoveFormProducto(index)}
          />
        </div>
    <Col md={4} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Nombre del producto</Form.Label>
    <Form.Select
    placeholder="Selecciona un producto"
    name='nombre'
    key={index}
    value={item.nombre}
    onChange={e => cambioData(index, e)}
    >
      <option value="">Selecciona un producto</option>
      {
        productos?.map((item) =>{
         return(
           <>
          <option  value={item.name}>{item.name}</option>
          </>
            )
                  })
      }
    </Form.Select>
    </Form.Group>
      </Col>
      <Col md={2} sm={6} xs={4} xl={2}>
      {
        (adicionales[index]?.nombre  === "" || adicionales[index]?.nombre  === undefined) ? 
        <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
      type="number" 
      name="precio"
      value={item.precio}
      key={index}
      onChange={e => cambioData(index, e )}
       />
       </Form.Group>
        
        :
            productos?.filter((itemProducto) =>{
              if(itemProducto.name === adicionales[index].nombre ){
                
                
                
                return itemProducto;
                
              }
              
            }).map((itemProducto) =>{
              return(
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio</Form.Label>
    <Form.Control 
    type="text" 
    name="precio"
    value={item.precio= itemProducto.precio}
    key={index}
    disabled
    onChange={e => cambioData(index, e )}
    />
    </Form.Group>
              )}
         )
          }
    
      </Col>
      <Col md={2} sm={6} xs={4} xl={2} >
      
      <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Piezas</Form.Label>
    <Form.Control 
    type="number" 
    name="piezas"
    value={item.piezas}
    key={index}
    onChange={e => cambioData(index,e )}
    />
    </Form.Group>
      </Col>
      {/* <Button onClick={removeProduct}>-</Button>
       <span>
         <p
         type="number" 
         name="piezas"
         value={item?.piezas}
         key={index}
         onChange={e => cambioData(index, e)}
         >
          {number}</p></span>
      <Button
      
      onClick={addProduct}>+</Button> */}
   {/*    </Col> */}
   <Col md={4} sm={6} xl={4} xs={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Total</Form.Label>
    <Form.Control 
    type="number" 
    name="total"
    key={index}
    disabled
    value={item.total = item.precio  * item.piezas}
    onChange={e => cambioData(index,e )} 
    />
    </Form.Group>
      </Col>
      </Row>
            </div>
        ))}
         <Button
         onClick={() => AddFormProducto()}
    >
      <Add/>
        Agregar Producto
    </Button>
    </Form>   
    </div>
  )
}
