import React, {useState, useEffect} from 'react';
import {Form, Button, Col, Row} from 'react-bootstrap';
import './EditUserForm.scss';
import {UpdateProductsDataApi} from '../../api/productos';
import {toast} from 'react-toastify';

//Crear formulario de edición de usuario y también crear ruta, para actualizar información del usuario
function EditUserForm({userdata ,setReload,setShow, token}) {
   
 /*  console.log(userdata) */
  const [updateUser, setUpdateUser] = useState({});
  useEffect(() => {
    setUpdateUser({
     name:userdata.name,
     precio:userdata.precio,
     descripcion:userdata.descripcion,
    })
   }, [userdata])



     /* Se actualizan los datos del puesto */
  const update = e =>{
    e.preventDefault();
    
/* 
    if(!updateDataUser.phone.length !==10){
      toast.error("Número de teléfono debe de contener 10 dígitos.")
      return;
    } */

  /*   if(!updateDataUser.name || !updateDataUser.email){
      toast.error("Nombre y e-mail son obligatorios.")
      return;
    } */
    
  
    UpdateProductsDataApi(token, updateUser, userdata._id).then(res =>{
      
      if(res.ok === true){
        toast.success(res.message);
        setShow(false);
        setReload(true);        
   }else{
      toast.error(res.message)
   }
      })
      
    
    }

    
  return (
    <Form onSubmit={update}>
      <Row>
      <Col md={12} sm={12} xl={12}>
      <Form.Group className="mb-3" controlId="formBasicEmail" id='label_switch'>
      <Form.Control 
      type="text" 
      placeholder="Nombre del paquete"
      value={updateUser.name}
      onChange={ e => setUpdateUser({...updateUser, name: e.target.value})}
      />
      </Form.Group>
        </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Control 
    type="number" 
    placeholder="precio del producto"
    value={updateUser.precio}
    onChange={e => setUpdateUser({...updateUser, precio: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={12} sm={12} xl={12}>
    <Form.Group  controlId="formBasicEmail">
    <Col md={12} sm={12} xl={12} className='body'>
              <Form.Control as="textarea" 
              rows={4}
              placeholder="Escribe una descripción del producto."
              value={updateUser.descripcion}
              onChange={ e => setUpdateUser({...updateUser, descripcion: e.target.value})}
              />
              </Col>
    </Form.Group>
      </Col>
       
      </Row>
    <Button variant="primary" type="submit">
      Actualizar
    </Button>
  </Form>
  );
}

export default EditUserForm;
