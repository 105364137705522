import React,{useState, useEffect} from 'react';
import {getPaqueteData} from '../../api/paquetes';
import { Container, Row, Col } from 'react-bootstrap';
import Slide1 from '../../assets/jpg/image1.jpg';
import Slide2 from '../../assets/jpg/image2.jpg';
import Slide3 from '../../assets/jpg/image4.jpg';
import Slide4 from '../../assets/jpg/image3.jpg';
import {ReactComponent as Check} from "../../assets/svg/check-solid.svg";
import './Personal.scss';
import {useParams} from 'react-router-dom'

export default function Personal() {
    const [paquete, setPaquete] = useState(undefined); 
    const params = useParams();
    
  /*   console.log(paquete) */
   useEffect(() => {
          (async () =>{
            const response = await getPaqueteData(params?.id);
            setPaquete(response.paquete);
          })()
        }, []);
    return (
        <Container fluid className='seccion_paquete_container' /* style={{background:paquete?.color, color:paquete?.colorTexto}} */>
            <Container>
            <Row>
            <Col md={12} sm={12} xl={12}>
                <h1>{paquete?.name ?? 'No se ingreso el nombre del paquete'}</h1>
            </Col>
            <Col md={6} sm={6} xl={6} xs={12} >
               <Row>
                   {
                       paquete?.descripcion ?
                       <Col md={12} sm={12} xl={12}> 
                       <p>
                           {paquete?.descripcion}
                       </p>
                       </Col> : '' 
                   }  
                <Descripcion descripciones={paquete?.descripciones}/>
             {/*    <h1>Precios</h1>
                <Col md={12} sm={12} xl={12}> 
               <h5>50 a 100 personas: $<span>{Intl.NumberFormat('es-MX').format(parseFloat(paquete?.basico))}</span></h5>
               </Col> 
               <Col md={12} sm={12} xl={12}> 
               <h5>101 a 150 personas: $<span>{Intl.NumberFormat('es-MX').format(parseFloat(paquete?.grande))}</span></h5>
               </Col>  */}
             </Row>
            </Col>
            <Col md={6} sm={6} xl={6} xs={12} className='container_image'>
               <Row>
               <Col md={6} sm={6} xl={6} className='card_image'>
                <div className="content"></div> 
                <img src={Slide1} alt="" />
            </Col>
            <Col md={6} sm={6} xl={6} className='card_image'>
            <div className="content"></div>
                <img src={Slide2} alt="" /> 
            </Col>
            <Col md={6} sm={6} xl={6} className='card_image'>
            <div className="content"></div>
                <img src={Slide3} alt="" />
            </Col>
            <Col md={6} sm={6} xl={6} className='card_image'>
            <div className="content"></div>
                <img src={Slide4} alt="" />
            </Col>
                </Row>
            </Col>
            </Row>
            </Container>
        </Container>
    )
}


const Descripcion = ({descripciones}) => {
    
    return(
        <Col md={12} sm={12} xl={12}>
                <h1 className='titulo_paquete'>Incluye:</h1>
                {
                    descripciones?.map((item =>{
                        return(
                       <div className="list_descripcion"
                       key={item?._id}
                       /* onClick={()=>deleteDescripcion(item)} */ /* key={item?.paquete} */>
                         <Check/>
                        <p>{item?.descripcion ?? 'No se agrego descripción'}</p>
                       </div>
                      )})) 
                }
            </Col>  
    )
   
}

