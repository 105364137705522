//Obtener datos del footer
export function getGalleryApi(id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/gallery/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

export function deleteImageApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/gallery/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.imagen) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}

export function signUpImageApi(imagen, token, lugar, sucursal, title) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/gallery/${lugar}`;
    const formData = new FormData();
    formData.append("imagen", imagen, imagen.name);
    formData.append("sucursal", sucursal);
    formData.append("title", title);
    const params = {
        method: "POST",
        body: formData,
        headers: {
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.image) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}