import React,{useState} from 'react';
import {Row, Col, Spinner, Form, Button} from 'react-bootstrap';
import {ReactComponent as Check} from "../../../assets/svg/check-solid.svg";
import {ReactComponent as File} from "../../../assets/svg/file-solid.svg";
import Modal from '../../../Components/ModalLg';
import Contrato from '../../Contrato';
import './AddPayment.scss';

;
export default function AddPayment({loading, total,abonos,abonoData, register, inputs, setInputs, dataNew, adminData,user}) {

  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(""); 
  const [modalContent, setModalContent] = useState(null);


  const descuento = inputs.descuento;
  const precioTotal = total - descuento;

  const porcentaje = precioTotal * (inputs.iva / 100);

  


  const verContrato = (dataNew, adminData, user) =>{
    setShow(true);
    setModalTitle(dataNew.name ? `${dataNew?.name}` : 'No agregaste el nombre del evento')
    setModalContent(<Contrato dataNew={dataNew} adminData={adminData} user={user}/>)
  }
  return (
    <div className='container__paymethod'>
      <Row>
      { 
      abonos.map((item, index) =>(
       <Row key={index}>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Método de pago</Form.Label>
    <Form.Select
    placeholder="Selecciona un número de personas"
    name='metodo'
    key={index}
    value={item.metodo}
    onChange={e => abonoData(index, e)}
    >
      <option value="No seleccionada">Selecciona una opción</option>
      <option value="Pago en efectivo">Pago en efectivo</option>
      <option value="Tarjeta de crédito">Tarjeta de crédito</option>
      <option value="Depósito bancario">Depósito bancario</option>
      <option value="Transferencia Electrónica">Transferencia Electrónica</option>
      <option value="¨Paypal">PayPal</option>
    </Form.Select>
    </Form.Group>
      </Col>
      <Col md={6} sm={12} xl={6}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Abono a cuenta</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Abono a cuenta"
    name="abono"
    value={item.abono}
    key={index}
    onChange={e => abonoData(index, e )}
    />
    </Form.Group>
      </Col>
    </Row>
    ))}
    <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Descuento</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Descuento"
    name="descuento"
    value={ inputs.descuento}
    onChange={e => setInputs({...inputs, descuento: e.target.value})}
    />
    </Form.Group>
    </Col>
    <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Total</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Total"
    name="total"
    value={inputs.precio =total}
    onChange={e => setInputs({...inputs, precioTotal: e.target.value})}
    />
    </Form.Group>
      </Col>

      <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>IVA</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="IVA"
    name="IVA"
    value={inputs.iva}
    onChange={e => setInputs({...inputs, iva: e.target.value})}
    />
    </Form.Group>
      </Col>

    <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio total</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Precio Total"
    name="Precio Total"
    value={inputs.precioTotal = precioTotal + porcentaje}
    /* value={inputs.precioTotal =total - parseFloat(inputs.descuento)} */
    onChange={e => setInputs({...inputs, precioTotal: e.target.value})}
    />
    </Form.Group>
      </Col>
    <div className="buttons">
      <Button className='btn_contrato' onClick={()=>verContrato(dataNew, adminData, user)}>
        <File/>
        Visualizar Contrato
        </Button>
    <Button 
     type="submit" 
     disabled={loading} 
     onClick={register}
     className='btn_evento'>
      {
        loading === false ?  <Check/> :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
     
      <p>Crear evento</p>
      
    </Button> 
    </div>
      </Row>
      <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    />
    </div>
  )
}
 