import React, {useState, useEffect} from 'react';
import {Container, Form, Col, Row, Button, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import AdicionalesTable from '../../../Components/AdiconalesTable';
import useAuth from '../../../hooks/useAuth';
import {getEventos, actualizaEvento} from '../../../api/eventos'; 
import {getPaquetesApi} from '../../../api/paquetes';
import Modal from '../../../Components/ModalNormal';
import EditDate from '../../../Components/EditDate';
import {ReactComponent as Add} from "../../../assets/svg/plus-square-regular.svg";
import moment from 'moment';
import AddAdicionalesService from '../../../Components/AgregaAdicionales';
import {toast} from 'react-toastify';
import 'moment/locale/es-mx'
import './EditEvent.scss';

export default function EditEvent() {
    const params = useParams();
    const {auth} = useAuth();
    const [reload, setReload] = useState(false);
    const [eventos, setEventos] = useState(undefined);
    const [paquetes, setPaquetes] = useState(undefined);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState(""); 
    const [modalContent, setModalContent] = useState(null);
    const [total, setTotal] = useState(0);
useEffect(() => {
    (async () =>{
      const response = await getEventos(auth?.token, params.id);
      setEventos(response.eventoData);
      setReload(false)
    })()
  }, [auth, reload]);
  


  const lugar = eventos?.lugar?._id;
  const date1 = "YYYY-MM-DD";
 
  const [updateEvento, setUpdateEvento] = useState({});
  useEffect(() => {
    setUpdateEvento({ 
     name:eventos?.name,
     guisado:eventos?.guisado,
     guisado1:eventos?.guisado1,
     guisado2:eventos?.guisado2,
     guisado3:eventos?.guisado3,
     guisado4:eventos?.guisado4,
     infantil:eventos?.infantil,
     pastel:eventos?.pastel,
     piñata:eventos?.piñata,
     refrescos:eventos?.refrescos,
     agua:eventos?.agua,
     dulceros:eventos?.dulceros,
     show:eventos?.show,
     comentarios:eventos?.comentarios,
     adicionales: eventos?.adicionales,
     turno:eventos?.turno,
     paquete:eventos?.paquete,
     precioPaquete:eventos?.precioPaquete,
     personas:eventos?.personas,
     lugar:eventos?.lugar?._id,
     descuento:eventos?.descuento,
     precio:eventos?.precio,
     precioTotal:eventos?.precioTotal,
     entrada: eventos?.entrada,
     salida: eventos?.salida,
     iva: eventos?.iva
    })
   }, [eventos])

   const sumaAbonos = eventos?.abonos.reduce((prev, next) => parseFloat(prev)  + parseFloat(next.abono), 0);
  

   const sumaAdicionales = eventos?.adicionales.reduce((prev, next) => prev + next.total, 0);
   



   useEffect(() => {
    setTotal(parseFloat(updateEvento.precioPaquete) + parseFloat(sumaAdicionales) /* - parseFloat(sumaAbonos) */);
   }, [updateEvento, sumaAdicionales, sumaAbonos]);

   useEffect(() => {
    (async () =>{
      const response = await getPaquetesApi(lugar);
      setPaquetes(response.paquetes);
    })()
  }, [lugar]);
 
  const update = async e =>{

    if(updateEvento.name === '' || updateEvento.fecha=== '' || updateEvento.turno === '' || updateEvento.paquete === '' || updateEvento.precioPaquete === ''){
      e.preventDefault();
      toast.error("Nombre del evento, fecha, horario, paquete y personas, son datos obligatorios.")
      return; 
    }
    e.preventDefault();  
  
      setLoading(true)
       const res = await actualizaEvento(auth.token, updateEvento, eventos?.uid);
       if(res.ok ===false){
        toast.error(res.message)
     }else{
        toast.success(res.message);
          setReload(true);   
          setShow(false);
     }
     setLoading(false);
     e.preventDefault(); 
    
    
  }

  const editfecha = data =>{
    setShow(true);
    setModalTitle('Modificar Fecha')
    setModalContent(<EditDate  setReload={setReload} setShow={setShow} token={auth.token} data={data} reload={reload}/>)
  }

 

  const descuento = updateEvento.descuento;
  
  const precioTotal = updateEvento.precio - descuento;
 

  const porcentaje = precioTotal * (updateEvento.iva / 100);



  return (
    <div className='eventos_user_container'>
    <div fluid className='users_section_nav'>
     <Container >
    <h5>{updateEvento.name}</h5>
     </Container>
   </div>
   <Container className='contaniner_form'>
   <Form onSubmit={update} className='formulario'>
      <Row>
        <Container>
         <p className='subtitulos'>Evento</p>
        <hr /> 
        </Container>
         <Col md={8} sm={12} xl={8}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Nombre del evento</Form.Label>
      <Form.Control 
      type="text"  
      placeholder="Nombre de usuario"
      value={updateEvento.name}
      onChange={ e => setUpdateEvento({...updateEvento, name: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
        <Form.Group className="mb-3">
        <Form.Label>Fecha del evento</Form.Label>
        <Form.Control 
    type="text" 
    placeholder="Seleccione una fecha"
    name="fecha"
    style={{width:'100%'}}
    value={moment(eventos?.fecha).format(date1)}
    onClick={()=>editfecha(eventos)}
    />
   </Form.Group>
        </Col> 
        <Col md={6} sm={12} xl={6}>
        <Form.Group className="mb-3">
        <Form.Label>Horario</Form.Label> 
    <Form.Select
    placeholder="Selecciona un Turno"
    onChange={e => setUpdateEvento({...updateEvento, turno: e.target.value})}
    value={updateEvento.turno}
    >
      <option value="">Selecciona un turno</option>
      <option value="Matutino">Matutino</option>
      <option value="Vespertino">Vespertino</option>
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={6} sm={12} xl={6}>
        <Form.Label>Paquete</Form.Label>
        <Form.Group className="mb-3">
    <Form.Select
    placeholder="Selecciona un paquete"
    onChange={e => setUpdateEvento({...updateEvento, paquete: e.target.value})}
    value={updateEvento.paquete}
    >
      <option value="">Selecciona un paquete</option>
      {
        paquetes?.map((item) =>{
         return(
          <option key={item._id} value={item.name}>{item.name}</option>
            )
                  })
      }
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={2} sm={12} xl={2}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Hora de entrada</Form.Label>
    <Form.Control 
    type="time" 
    placeholder="Hora de entrada"
    name="entrada"
    value={updateEvento.entrada}
    onChange={e => setUpdateEvento({...updateEvento, entrada: e.target.value})}
    />
    </Form.Group>
      </Col>
      <Col md={2} sm={12} xl={2}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Hora de salida</Form.Label>
    <Form.Control 
    type="time" 
    placeholder="Hora de salida"
    name="salida"
    value={updateEvento.salida}
    onChange={e => setUpdateEvento({...updateEvento, salida: e.target.value})}
    />
    </Form.Group>
      </Col>
        <Col md={4} sm={12} xl={4}>
        <Form.Group className="mb-3">
        <Form.Label>Personas</Form.Label>
    <Form.Select
    placeholder="Selecciona un número de personas"
    onChange={e => setUpdateEvento({...updateEvento, precioPaquete: e.target.value})}
    value={updateEvento.precioPaquete}
    >
      <option value="">Selecciona número de personas</option>
      {
        paquetes?.filter(item =>{
          if(item.name === updateEvento.paquete  ){
            
            return item;
          
          }
          
        }).map((item) =>{
         return(
           <>
          <option  value={item.basico}>50 a 100 personas</option>
          <option  value={item.grande}>101 a 150 personas</option>
          </>
            )
                  })
      }
    </Form.Select>
   </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Numero de personas</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Numero de personas que asistirán al evento"
    name="personas"
    value={updateEvento.personas}
    onChange={e => setUpdateEvento({...updateEvento, personas: e.target.value})}
    />
    </Form.Group>
      </Col>
        <Container>
         <p className='subtitulos'>Guisados</p>
        <hr /> 
        </Container>
      <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Guisado 1</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Guisado1"
      value={updateEvento.guisado}
      onChange={ e => setUpdateEvento({...updateEvento, guisado: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Guisado 2</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Guisado2"
      value={updateEvento.guisado1}
      onChange={ e => setUpdateEvento({...updateEvento, guisado1: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Guisado 3</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Guisado3"
      value={updateEvento.guisado2}
      onChange={ e => setUpdateEvento({...updateEvento, guisado2: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Guisado 4</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Guisado4"
      value={updateEvento.guisado3}
      onChange={ e => setUpdateEvento({...updateEvento, guisado3: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Guisado 5</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Guisado5"
      value={updateEvento.guisado4}
      onChange={ e => setUpdateEvento({...updateEvento, guisado4: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Menú infantil</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Menú infantil"
      value={updateEvento.infantil}
      onChange={ e => setUpdateEvento({...updateEvento, infantil: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Container>
         <p className='subtitulos'>Otros elementos</p>
        <hr /> 
        </Container> 
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Pastel</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Pastel"
      value={updateEvento.pastel}
      onChange={ e => setUpdateEvento({...updateEvento, pastel: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Piñata</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Piñata"
      value={updateEvento.piñata}
      onChange={ e => setUpdateEvento({...updateEvento, piñata: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Refrescos</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Refrescos"
      value={updateEvento.refrescos}
      onChange={ e => setUpdateEvento({...updateEvento, refrescos: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Agua</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Agua"
      value={updateEvento.agua}
      onChange={ e => setUpdateEvento({...updateEvento, agua: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Dulceros</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Dulceros"
      value={updateEvento.dulceros}
      onChange={ e => setUpdateEvento({...updateEvento, dulceros: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={4} sm={12} xl={4}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Show</Form.Label>
      <Form.Control 
      type="text" 
      placeholder="Show"
      value={updateEvento.show}
      onChange={ e => setUpdateEvento({...updateEvento, show: e.target.value})}
      />
      </Form.Group>
        </Col>
        <Col md={12} sm={12} xl={12}>
        <Form.Group  controlId="ControlTextarea1">
        <Form.Label>Comentarios</Form.Label>
        <Form.Control 
         as="textarea"
         rows={3}
         placeholder="Comentario"
         value={updateEvento.comentarios}
         onChange={ e => setUpdateEvento({...updateEvento, comentarios: e.target.value})}
         />
        </Form.Group>
        </Col>
        <p></p>
        <Container>
        <p className='subtitulos'>Adicionales</p>
        <hr />
        </Container>
        <AdicionalesTable adicionales={eventos?.adicionales} setReload={setReload} reload={reload} uid={eventos?.uid}/>
        <Container>
        <p className='subtitulos'>Totales</p>
        <hr />
        </Container>
        <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Descuento</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Descuento"
    name="descuento"
    value={updateEvento.descuento}
    onChange={e => setUpdateEvento({...updateEvento, descuento: e.target.value})}
    /* disabled */
    />
    </Form.Group>
    </Col>
    <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>SubTotal</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Subtotal"
    name="total"
    value={updateEvento.precio = total}
    onChange={e => setUpdateEvento({...updateEvento, precio: e.target.value})}
    disabled
    />
    </Form.Group>
      </Col> 
      <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>IVA</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="IVA"
    name="IVA"
    value={updateEvento.iva}
    onChange={e => setUpdateEvento({...updateEvento, iva: e.target.value})}
    />
    </Form.Group>
      </Col>
    <Col md={6} sm={12} xl={4}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Precio total</Form.Label>
    <Form.Control 
    type="number" 
    placeholder="Precio Total"
    name="Precio Total"
    value={updateEvento.precioTotal = precioTotal + porcentaje}
    onChange={e => setUpdateEvento({...updateEvento, precioTotal: e.target.value})}
    disabled
    />
    </Form.Group>
      </Col>
      </Row>
      <AddEvento 
                  data={eventos} 
                  setShow={setShow} 
                  setModalTitle={setModalTitle} 
                  setModalContent={setModalContent} 
                  setReload={setReload}
                  reload={reload}
                  token={auth.token}
                  />
    <Button variant="primary" className='Button_actualizar' type="submit" disabled={loading}>
    {
        loading === false ? '' :
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      }
      Actualizar
    </Button>
  </Form>
   </Container>
   <Modal
    show={show}
    setShow={setShow}
    title={modalTitle}
    body={modalContent}
    />
    </div>
  )
}


const AddEvento = ({data, setShow, setModalTitle, setModalContent, setReload, token, reload}) => {
   
  const editUser = data =>{
    setShow(true);
    setModalTitle('Agregar Adicional')
    setModalContent(<AddAdicionalesService  setReload={setReload} setShow={setShow} token={token} auth={data?.uid} reload={reload}/>)
  }
  return (
      <>
      <Button variant="primary"  className='Button_add' onClick={()=>editUser(data)}>
        <Add/>
      Adicional
    </Button>
      </>
    
  );
}