import React from 'react';
import Banner from '../../Components/web/Banner';
import Videos from '../../Components/web/Videos';
import Paquetes from '../../Components/web/Paquetes';
import Sucursales from '../../Components/web/Sucursales';
import {Helmet} from 'react-helmet';
import './Principal.scss';

export default function Principal() {
    return (
        <>
        <Helmet>
            <title>Inflapark | Parque inflable | Salón de fiestas infantiles | Eventos infantiles</title>
            <meta name='description' content='Los mejores eventos infantiles, solo los tendrás en Inflapark, contamos con lugares con distintas temáticas, para que tus pequeños se diviertan a lo grande.'  data-react-helmet="true"/>
            <meta name='og:description' content='Los mejores eventos infantiles, solo los tendrás en Inflapark, contamos con lugares con distintas temáticas, para que tus pequeños se diviertan a lo grande.' data-react-helmet="true"/>
            <meta name='og:title' content="Inflapark | Parque inflable | Salón de fiestas infantiles | Eventos infantiles" data-react-helmet="true"/>
            <meta property="og:url" content="https://inflapark.com.mx/" data-react-helmet="true"/>
            <meta property="og:site_name" content='Parque inflable'  data-react-helmet="true"/>
            <meta property="article:publisher" content="https://www.facebook.com/inflapark" data-react-helmet="true"/>
            <meta name="og:image" content="https://res.cloudinary.com/dhecyqald/image/upload/v1685146144/utils/logo_black_cbbmc0.jpg" data-react-helmet="true"/>
        
        </Helmet>
            <Banner/>
            <Videos/> 
            <Paquetes/>
            <Sucursales/>
        </>
    )
}
