//Agrega decripciones a cada paquete
export function createProductApi(data, token) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/product`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.producto) {
                return { ok: true, message: 'Se agrego un nuevo producto' };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}


//Obtener productos
export function getProductosApi() {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/product`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    };

    return fetch(url, params)
        .then(response => {
            return response.json()
                .then(result => {
                    return result;
                })
                .catch(err => {
                    return err.message;
                })
        })
}

//Actualiza los datos de la usuario
export function UpdateProductsDataApi(token, data, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        },
        body: JSON.stringify(data)
    }
    return fetch(url, params).then(response => {
            return response.json();
        })
        .then(result => {
            if (result.producto) {
                return { ok: true, result, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });

}


export function DeleteProductApi(token, id) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
        }
    }
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            if (result.producto) {
                return { ok: true, message: result.msg };
            }
            return { ok: false, message: result.msg };
        })
        .catch(err => {
            return { ok: false, message: err.msg };
        });
}