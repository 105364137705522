import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import NavBar from '../Components/NavbarUser';
import {Container} from 'react-bootstrap';
import useAuth from '../hooks/useAuth';


export default function LayoutAdmin(props) {
    const {routes} = props;
    const {auth} = useAuth();

    if(!auth ){
        return(
          <>
          <Route path="/"/>
          <Redirect to ="/" />
          </>
        )
      }
    
      if(auth ){
        return (
            <div>
             <NavBar/>
             <Container>
              <LoadRoutes routes={routes}/>   
             </Container>
            </div>
          )  
      }
    
     return null;
    
    
  }
  
  
  function LoadRoutes({ routes }) {
      /* console.log(routes) */
    return ( 
    <Switch> {
            routes.map((route, index) => (
                 <Route key = { index }
                 path = { route.path }
                 exact = { route.exact }
                 component = { route.component }
                />
            ))
        } 
        </Switch>
    );
  
  }